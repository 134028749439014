import React from 'react'

export const Shield = ({
                              style = {},
                              fill = '#141006',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 480 509.2',
                          }) =>

    <svg
        width="39.5"
        height="42"
        viewBox="0 0 39.5 42"
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 480 509.2">
     <g id="Layer_1-2" data-name="Layer 1">
        <path d="M57,127L240,49.4l183,77.6c5.9,2.5,9.1,7.8,9,12.8-.4,91.4-38.4,249.3-186.3,320.1-3.6,1.7-7.8,1.7-11.3,0C86.4,389,48.5,231.2,48,139.7c0-5,3.1-10.2,9-12.8v.1ZM441.7,82.8L253.4,2.9c-4.2-1.9-8.7-2.9-13.4-2.9s-9.2,1-13.4,2.9L38.3,82.8C16.3,92.1-.1,113.8,0,140c.5,99.2,41.3,280.7,213.6,363.2,16.7,8,36.1,8,52.8,0,172.4-82.5,213.1-264,213.6-363.2.1-26.2-16.3-47.9-38.3-57.2ZM296,208c0-30.9-25.1-56-56-56s-56,25.1-56,56c0,22.3,13.1,41.6,32,50.6v69.4c0,13.3,10.7,24,24,24s24-10.7,24-24v-69.4c18.9-9,32-28.3,32-50.6Z" fill="#141006"/>
    </g>
</svg>

export default Shield