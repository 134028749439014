import React from 'react'

export const HowItWorksStep2 = ({
                                   style = {},
                                   fill = '#000000',
                                   width = '100%',
                                   className = '',
                                   height = '100%',
                                   viewBox = '0 0 134.58 95',
                                 }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M133.6,22.28c0-5.67-4.62-10.24-10.31-10.24H49.51c-5.7,0-10.31,4.6-10.31,10.24v10.01h94.4v-10.01Z" fill="#ddf2f7"/>
      <path d="M134.58,33.26H38.2v-10.98c0-6.19,5.05-11.22,11.29-11.22h73.79c6.23,0,11.29,5.04,11.29,11.22v10.98h.02ZM40.16,31.32h92.44v-9.04c0-5.12-4.19-9.27-9.33-9.27H49.5c-5.15,0-9.33,4.17-9.33,9.27v9.04h-.02Z" fill="#003057"/>
      <path d="M39.18,32.31v46.9c0,5.67,4.62,10.24,10.31,10.24h73.79c5.7,0,10.3-4.6,10.3-10.24v-46.9H39.18Z" fill="#fff"/>
      <path d="M123.27,90.42H49.5c-6.23,0-11.29-5.04-11.29-11.22V31.34h96.36v47.87c0,6.19-5.08,11.22-11.29,11.22h-.02ZM40.16,33.26v45.92c0,5.12,4.19,9.27,9.33,9.27h73.79c5.15,0,9.33-4.17,9.33-9.27v-45.92H40.16Z" fill="#003057"/>
      <path d="M64.58.97h-.02c-2.42,0-4.39,1.95-4.39,4.36v11.51c0,2.41,1.96,4.36,4.39,4.36h.02c2.42,0,4.39-1.95,4.39-4.36V5.34c0-2.41-1.96-4.36-4.39-4.36Z" fill="#ddf2f7"/>
      <path d="M64.56,22.16c-2.96,0-5.37-2.4-5.37-5.34V5.34c0-2.94,2.41-5.34,5.37-5.34s5.37,2.4,5.37,5.34v11.49c0,2.94-2.41,5.34-5.37,5.34ZM64.56,1.95c-1.88,0-3.41,1.52-3.41,3.39v11.49c0,1.87,1.53,3.39,3.41,3.39s3.41-1.52,3.41-3.39V5.34c0-1.87-1.53-3.39-3.41-3.39Z" fill="#003057"/>
      <path d="M108.25.97h-.02c-2.42,0-4.39,1.95-4.39,4.36v11.51c0,2.41,1.97,4.36,4.39,4.36h.02c2.42,0,4.39-1.95,4.39-4.36V5.34c0-2.41-1.97-4.36-4.39-4.36Z" fill="#ddf2f7"/>
      <path d="M108.23,22.16c-2.96,0-5.37-2.4-5.37-5.34V5.34c0-2.94,2.41-5.34,5.37-5.34s5.37,2.4,5.37,5.34v11.49c0,2.94-2.41,5.34-5.37,5.34ZM108.23,1.95c-1.88,0-3.41,1.52-3.41,3.39v11.49c0,1.87,1.53,3.39,3.41,3.39s3.41-1.52,3.41-3.39V5.34c0-1.87-1.53-3.39-3.41-3.39Z" fill="#003057"/>
      <path d="M61.6,41.44h-6.99c-1.63,0-2.96,1.32-2.96,2.94v6.95c0,1.62,1.32,2.94,2.96,2.94h6.99c1.63,0,2.96-1.32,2.96-2.94v-6.95c0-1.62-1.32-2.94-2.96-2.94Z" fill="#fff"/>
      <path d="M61.6,55.25h-6.99c-2.17,0-3.94-1.75-3.94-3.91v-6.95c0-2.16,1.76-3.91,3.94-3.91h6.99c2.17,0,3.94,1.75,3.94,3.91v6.95c0,2.16-1.76,3.91-3.94,3.91ZM54.6,42.42c-1.1,0-1.98.88-1.98,1.97v6.95c0,1.09.88,1.97,1.98,1.97h6.99c1.1,0,1.98-.88,1.98-1.97v-6.95c0-1.09-.88-1.97-1.98-1.97h-6.99Z" fill="#003057"/>
      <path d="M89.89,41.44h-6.99c-1.63,0-2.96,1.32-2.96,2.94v6.95c0,1.62,1.32,2.94,2.96,2.94h6.99c1.63,0,2.96-1.32,2.96-2.94v-6.95c0-1.62-1.32-2.94-2.96-2.94Z" fill="#00c7b1"/>
      <path d="M89.89,55.25h-6.99c-2.17,0-3.94-1.75-3.94-3.91v-6.95c0-2.16,1.76-3.91,3.94-3.91h6.99c2.17,0,3.94,1.75,3.94,3.91v6.95c0,2.16-1.76,3.91-3.94,3.91ZM82.9,42.42c-1.1,0-1.98.88-1.98,1.97v6.95c0,1.09.88,1.97,1.98,1.97h6.99c1.1,0,1.98-.88,1.98-1.97v-6.95c0-1.09-.88-1.97-1.98-1.97h-6.99Z" fill="#003057"/>
      <path d="M118.18,41.44h-7c-1.63,0-2.96,1.32-2.96,2.94v6.95c0,1.62,1.32,2.94,2.96,2.94h7c1.63,0,2.96-1.32,2.96-2.94v-6.95c0-1.62-1.32-2.94-2.96-2.94Z" fill="#fff"/>
      <path d="M118.18,55.25h-6.99c-2.17,0-3.94-1.75-3.94-3.91v-6.95c0-2.16,1.76-3.91,3.94-3.91h6.99c2.18,0,3.94,1.75,3.94,3.91v6.95c0,2.16-1.76,3.91-3.94,3.91ZM111.19,42.42c-1.1,0-1.98.88-1.98,1.97v6.95c0,1.09.88,1.97,1.98,1.97h6.99c1.1,0,1.98-.88,1.98-1.97v-6.95c0-1.09-.88-1.97-1.98-1.97h-6.99Z" fill="#003057"/>
      <path d="M89.89,63.97h-6.99c-1.63,0-2.96,1.32-2.96,2.94v6.95c0,1.62,1.32,2.94,2.96,2.94h6.99c1.63,0,2.96-1.32,2.96-2.94v-6.95c0-1.62-1.32-2.94-2.96-2.94Z" fill="#fff"/>
      <path d="M89.89,77.78h-6.99c-2.17,0-3.94-1.75-3.94-3.91v-6.95c0-2.16,1.76-3.91,3.94-3.91h6.99c2.17,0,3.94,1.75,3.94,3.91v6.95c0,2.16-1.76,3.91-3.94,3.91ZM82.9,64.95c-1.1,0-1.98.88-1.98,1.97v6.95c0,1.09.88,1.97,1.98,1.97h6.99c1.1,0,1.98-.88,1.98-1.97v-6.95c0-1.09-.88-1.97-1.98-1.97h-6.99Z" fill="#003057"/>
      <path d="M118.18,63.97h-7c-1.63,0-2.96,1.32-2.96,2.94v6.95c0,1.62,1.32,2.94,2.96,2.94h7c1.63,0,2.96-1.32,2.96-2.94v-6.95c0-1.62-1.32-2.94-2.96-2.94Z" fill="#fff"/>
      <path d="M118.18,77.78h-6.99c-2.17,0-3.94-1.75-3.94-3.91v-6.95c0-2.16,1.76-3.91,3.94-3.91h6.99c2.18,0,3.94,1.75,3.94,3.91v6.95c0,2.16-1.76,3.91-3.94,3.91ZM111.19,64.95c-1.1,0-1.98.88-1.98,1.97v6.95c0,1.09.88,1.97,1.98,1.97h6.99c1.1,0,1.98-.88,1.98-1.97v-6.95c0-1.09-.88-1.97-1.98-1.97h-6.99Z" fill="#003057"/>
      <path d="M83.13,52.33c-.25,0-.49-.1-.69-.27-.39-.37-.39-.99,0-1.38l6.66-6.82c.37-.39,1-.39,1.39,0,.39.37.39.99,0,1.38l-6.66,6.82c-.2.19-.45.29-.71.29v-.02Z" fill="#003057"/>
      <path d="M89.79,52.33c-.25,0-.51-.1-.71-.29l-6.66-6.82c-.37-.39-.37-.99,0-1.38.39-.37,1.02-.37,1.39,0l6.66,6.82c.37.39.37.99,0,1.38-.2.18-.43.27-.69.27v.02Z" fill="#003057"/>
      <path d="M16.73,74.55c4.16,0,7.52-3.35,7.52-7.48s-3.37-7.48-7.52-7.48-7.52,3.35-7.52,7.48,3.37,7.48,7.52,7.48Z" fill="#00c7b1"/>
      <path d="M16.73,75.53c-4.68,0-8.5-3.8-8.5-8.45s3.82-8.45,8.5-8.45,8.5,3.8,8.5,8.45-3.82,8.45-8.5,8.45ZM16.73,60.57c-3.6,0-6.54,2.92-6.54,6.5s2.94,6.5,6.54,6.5,6.54-2.92,6.54-6.5-2.94-6.5-6.54-6.5Z" fill="#003057"/>
      <path d="M.98,91.88v-2.9c0-7.03,5.72-12.72,12.79-12.72h5.9c7.07,0,12.79,5.69,12.79,12.72v2.9c0,1.19-.96,2.14-2.16,2.14H3.14c-1.2,0-2.16-.95-2.16-2.14Z" fill="#00c7b1"/>
      <path d="M30.31,95H3.13c-1.72,0-3.13-1.4-3.13-3.12v-2.9c0-7.56,6.17-13.69,13.77-13.69h5.9c7.6,0,13.77,6.13,13.77,13.69v2.9c0,1.71-1.41,3.12-3.13,3.12ZM13.77,77.24c-6.5,0-11.81,5.26-11.81,11.74v2.9c0,.64.53,1.17,1.18,1.17h27.19c.65,0,1.18-.53,1.18-1.17v-2.9c0-6.47-5.31-11.74-11.81-11.74h-5.92Z" fill="#003057"/>
      <path d="M57.9,74.55c4.16,0,7.52-3.35,7.52-7.48s-3.37-7.48-7.52-7.48-7.52,3.35-7.52,7.48,3.37,7.48,7.52,7.48Z" fill="#00c7b1"/>
      <path d="M57.9,75.53c-4.68,0-8.5-3.8-8.5-8.45s3.82-8.45,8.5-8.45,8.5,3.8,8.5,8.45-3.82,8.45-8.5,8.45ZM57.9,60.57c-3.61,0-6.54,2.92-6.54,6.5s2.94,6.5,6.54,6.5,6.54-2.92,6.54-6.5-2.94-6.5-6.54-6.5Z" fill="#003057"/>
      <path d="M42.14,91.88v-2.9c0-7.03,5.72-12.72,12.79-12.72h5.9c7.07,0,12.79,5.69,12.79,12.72v2.9c0,1.19-.96,2.14-2.16,2.14h-27.19c-1.2,0-2.16-.95-2.16-2.14h.02Z" fill="#00c7b1"/>
      <path d="M71.49,95h-27.19c-1.72,0-3.13-1.4-3.13-3.12v-2.9c0-7.56,6.17-13.69,13.77-13.69h5.9c7.6,0,13.77,6.13,13.77,13.69v2.9c0,1.71-1.41,3.12-3.13,3.12h.02ZM54.94,77.24c-6.5,0-11.81,5.26-11.81,11.74v2.9c0,.64.53,1.17,1.18,1.17h27.19c.65,0,1.18-.53,1.18-1.17v-2.9c0-6.47-5.31-11.74-11.81-11.74h-5.92Z" fill="#003057"/>
    </g>
  </svg>;

export default HowItWorksStep2