import React from 'react'

export const CoachesMore = ({
                      style = {},
                      fill = '#fff',
                      width = '100%',
                      className = '',
                      height = '100%',
                      viewBox = '0 0 16.8 28',
                    }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M16.35,15.09l-12.32,12.46c-.59.6-1.56.6-2.15,0l-1.44-1.45c-.59-.6-.59-1.57,0-2.17l9.76-9.92L.44,4.08c-.59-.6-.59-1.57,0-2.17L1.88.45C2.48-.15,3.44-.15,4.03.45l12.32,12.46c.59.6.59,1.58,0,2.18Z" fill="#0032a0"/>
    </g>  </svg>;

export default CoachesMore