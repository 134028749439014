export default class ValidationService {

  static welcome(values) {
    const errors = {}

    if (!values.username) {
      errors.username = 'app.errors.email.required'
    } else if (!ValidationService.isValidEmailFormat(values.username)) {
      errors.username = 'app.errors.email.invalidFormat'
    }

    return errors
  }

  static verifyTempcode(values) {
    const errors = {}

    if (!values.singleUseCode) {
      errors.singleUseCode = 'app.b-flow.errors.tempcode.required'
    }

    return errors
  }

  static scheduleADemo(values) {
    const errors = {}

    if (!values.name) {
      errors.name = 'app.errors.customers.schedule-a-demo.name'
    }
    if (!values.companyName) {
      errors.companyName = 'app.errors.customers.schedule-a-demo.employer-name'
    }
    if (!values.sizeOfWorkforce) {
      errors.sizeOfWorkforce = 'app.errors.customers.schedule-a-demo.number-of-employees'
    }

    return errors
  }

  static login(values) {
    const errors = {}

    if (!values.password) {
      errors.password = 'app.errors.password.required'
    }

    return errors
  }

  static createPassword(values) {
    const errors = {}

    if (!values.newpassword) {
      errors.newpassword = 'app.errors.password.required'
    } else if (values.newpassword.length < 8) {
      errors.newpassword = 'app.errors.password.length';
    } else if (!ValidationService.isValidUpper(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containupper';
    } else if (!ValidationService.isValidLower(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containlower';
    } else if (!ValidationService.isValidNumber(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containnumber';
    } else if (ValidationService.isValidNoSpecial(values.newpassword)) {
      errors.newpassword = 'app.errors.password.notcontainspecial';
    }
    // validation for containing at least one special character
    // else if (ValidationService.isValidWithSpecial(values.newpassword)) {
    //   errors.newpassword = 'app.errors.password.containspecial';
    // }

    if (values.newpassword !== values.confirmpassword) {
      errors.confirmpassword = 'app.errors.password.not-match'
    }

    return errors
  }

  static changePassword(values) {
    const errors = {}

    if (!values.newpassword) {
      errors.newpassword = 'app.errors.password.required'
    } else if (values.newpassword.length < 8) {
      errors.newpassword = 'app.errors.password.length';
    } else if (!ValidationService.isValidUpper(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containupper';
    } else if (!ValidationService.isValidLower(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containlower';
    } else if (!ValidationService.isValidNumber(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containnumber';
    } else if (ValidationService.isValidNoSpecial(values.newpassword)) {
      errors.newpassword = 'app.errors.password.notcontainspecial';
    }

    if (values.newpassword !== values.confirmpassword) {
      errors.confirmpassword = 'app.errors.password.not-match'
    }

    return errors
  }

  static changePhone(values) {
    const errors = {}

    if (!values.newCellPhone) {
      errors.newCellPhone = 'app.errors.mobilePhone.required'
    }
    else if(values.newCellPhone.length!=14){
      errors.newCellPhone = 'app.errors.mobilePhone.invalidFormat'
    }

    return errors
  }

  static changeEmail(values) {
    const errors = {}

    if (!values.newUsername) {
      errors.newUsername = 'app.errors.email.required'
    }
    else if (!ValidationService.isValidEmailFormat(values.newUsername)){
      errors.newUsername = 'app.errors.email.invalidFormat'
    }

    return errors
  }

  static isValidUpper(value){
    return (/[A-Z]/.test(value));
  }

  static isValidLower(value){
    return (/[a-z]/.test(value));
  }

  static isValidNumber(value){
    return (/[0-9]/.test(value));
  }

  static isValidNoSpecial(value){
    return (/[~`!@#$%^&*()_+={}\[\]:;"'<>,.?/|\\]/.test(value));
  }

  static isValidWithSpecial(value){
    return (/^(?!.*([!$%^&*()_+|~=`{}\[\]:";'<>?,.\/-]).*)[!$%^&*()_+|~=`{}\[\]:";'<>?,.\/A-Za-z0-9-]+$/.test(value));
  }

  static isValidEmailFormat(value){
    return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value));
  }

  //not used on front end, handled in the backend but worth keeping
  static containsApostrophe(value) {
    return (/([\w]+['][\w]+)/.test(value));
  }

  static fullName(values) {
    const errors = {}
    errors.page=[]

    if (!values.firstName) {
      errors.firstName = 'app.errors.firstName.required'
      errors.page.push('app.errors.firstName.required.notice');
    }

    if (!values.lastName) {
      errors.lastName = 'app.errors.lastName.required'
      errors.page.push('app.errors.lastName.required.notice');
    }

    if (!values.agreedToTerms) {
      errors.agreedToTerms = 'app.errors.agreedToTerms.required'
      errors.page.push('app.errors.agreedToTerms.required.notice');
    }

    return errors;
  }

  static validateCpCreatePasswordForm(values) {
    const errors = {}
    errors.page=[]

    if (!values.newpassword) {
      errors.newpassword = 'app.errors.password.required'
    }
    else if (values.newpassword.length < 8 ){
      errors.newpassword = 'app.errors.password.length';
    }
    else if (!ValidationService.isValidUpper(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containupper';
    }
    else if (!ValidationService.isValidLower(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containlower';
    }
    else if (!ValidationService.isValidNumber(values.newpassword)) {
      errors.newpassword = 'app.errors.password.containnumber';
    }
    else if (ValidationService.isValidNoSpecial(values.newpassword)) {
      errors.newpassword = 'app.errors.password.notcontainspecial';
    }
    if (values.newpassword !== values.confirmpassword) {
      errors.confirmpassword = 'app.errors.password.not-match'
    }
    if (!values.agreedToTerms) {
      errors.agreedToTerms = 'app.errors.agreedToTerms.required'
      errors.page.push('app.errors.agreedToTerms.required.notice');
    }

    return errors
  }

  static cpValidateContractSubmission(values) {
    const errors = {}
    errors.page=[]

    if (!values.customerSignedName) {
      errors.customerSignedName = 'app.errors.customerSignedName.required'
      errors.page.push('app.errors.customerSignedName.required.notice');
    }

    if (!values.customerTitle) {
      errors.customerTitle = 'app.errors.customerTitle.required'
      errors.page.push('app.errors.customerTitle.required.notice');
    }

    if (!values.customerAuthorization) {
      errors.customerAuthorization = 'app.errors.customerAuthorization.required'
      errors.page.push('app.errors.customerAuthorization.required.notice');
    }

    return errors;
  }

  static validateRegistrationForm(values) {
    const errors = {}
    errors.page=[]

    if (!values.firstName) {
      errors.firstName = 'app.errors.firstName.required'
      errors.page.push('app.errors.firstName.required.notice');
    }

    if (!values.lastName) {
      errors.lastName = 'app.errors.lastName.required'
      errors.page.push('app.errors.lastName.required.notice');
    }

    if (!values.email) {
      errors.email = 'app.errors.email.required'
      errors.page.push('app.errors.email.required.notice');
    }
    else if (!ValidationService.isValidEmailFormat(values.email)){
      errors.email = 'app.errors.email.invalidFormat'
      errors.page.push('app.errors.email.invalidFormat.notice');
    }

    if (!values.mobilePhone) {
      errors.mobilePhone = 'app.errors.mobilePhone.required'
      errors.page.push('app.errors.mobilePhone.required.notice');
     }

    else if( values.mobilePhone.length < 14 || values.mobilePhone.length != 16) {
        if (values.mobilePhone.charAt(0) == 1 && values.mobilePhone.length != 16) {
          errors.mobilePhone = 'app.errors.mobilePhone.invalidFormat'
          errors.page.push('app.errors.mobilePhone.invalidFormat.notice');
        }
        if (values.mobilePhone.charAt(0) != 1 && values.mobilePhone.length != 14) {
          errors.mobilePhone = 'app.errors.mobilePhone.invalidFormat'
          errors.page.push('app.errors.mobilePhone.invalidFormat.notice');
        }
    }

    if (!values.zipCode) {
      errors.zipCode = 'app.errors.zipCode.required'
      errors.page.push('app.errors.zipCode.required.notice');
    }
    else if (values.zipCode.length!=5 && values.zipCode.length!=10){
      errors.zipCode = 'app.errors.zipCode.invalidFormat'
      errors.page.push('app.errors.zipCode.invalidFormat.notice');
    }

    if (!values.agreedToTerms) {
      errors.agreedToTerms = 'app.errors.agreedToTerms.required'
      errors.page.push('app.errors.agreedToTerms.required.notice');
    }

    if (values.callType === 'submit-video-chat' && values.videoChatService === 'Please select from the following') {
      errors.videoChatService = 'app.errors.videoChatService.required'
      errors.page.push('app.errors.videoChatService.required.notice');
    }

    return errors
  }

  static validateVideoChatService(values) {
    const errors = {}
    errors.page=[]

    if (values.callType === 'submit-video-chat' && values.videoChatService === 'Please select from the following') {
      console.log("Validation for Call Type is " + values.callType + " : " + values.videoChatService);
      errors.videoChatService = 'app.errors.videoChatService.required'
      errors.page.push('app.errors.videoChatService.required.notice');
    }

    return errors
  }


  //errors.<fieldname> ties error message that is displayed under the field
  //error.anything stops the button from being enabled
  static validateCreditReportFormFields(values){
    const errors = {}
    errors.page=[]

    if (!values.street) {
      errors.street = 'app.errors.street.required'
      errors.page.push('app.errors.street.required.notice');
    }

    if (!values.city) {
      errors.city = 'app.errors.city.required'
      errors.page.push('app.errors.city.required.notice');
    }

    if (!values.state) {
      errors.state = 'app.errors.state.required'
      errors.page.push('app.errors.state.required.notice');
    }

    if (!values.zipCode) {
      errors.zipCode = 'app.errors.zipCode.required'
      errors.page.push('app.errors.zipCode.required.notice');
    }
    else if (values.zipCode.length!=5 && values.zipCode.length!=10){
      errors.zipCode = 'app.errors.zipCode.invalidFormat'
      errors.page.push('app.errors.zipCode.invalidFormat.notice');
    }

    if (!values.birthdate) {
      errors.birthdate = 'app.errors.birthdate.required'
      errors.page.push('app.errors.birthdate.required.notice');
    }
    else if (!(ValidationService.isValidDate(values))){
      errors.birthdate = 'app.errors.birthdate.invalidFormat';
      errors.page.push('app.errors.birthdate.invalidFormat.notice');
    }
    else if (!ValidationService.isOfAge(values)){
      errors.birthdate ='app.errors.birthdate.notOfAge';
      errors.birthdateNotice ='app.errors.birthdate.notOfAge.notice';
    }
    else if (!ValidationService.isToOld(values)){
      errors.birthdate ='app.errors.birthdate.isToOld';
      errors.birthdateNotice ='app.errors.birthdate.isToOld.notice';
    }

    if (!values.ssn) {
      errors.ssn = 'app.errors.ssn.required'
      errors.page.push('app.errors.ssn.required.notice');
    }
    else if (values.ssn.length!=11) {
      errors.ssn = 'app.errors.ssn.invalidFormat'
      errors.page.push('app.errors.ssn.invalidFormat.notice');
    }

    return errors;
  }

  //errors.<fieldname> ties error message that is displayed under the field
  //error.anything stops the button from being enabled
  static validateDebtSummaryCreditReportFormFields(values){
    const errors = {}
    errors.page=[]

    if (!values.street) {
      errors.street = 'app.errors.street.required'
      errors.page.push('app.errors.street.required.notice');
    }

    if (!values.city) {
      errors.city = 'app.errors.city.required'
      errors.page.push('app.errors.city.required.notice');
    }

    if (!values.state) {
      errors.state = 'app.errors.state.required'
      errors.page.push('app.errors.state.required.notice');
    }

    if (!values.zipCode) {
      errors.zipCode = 'app.errors.zipCode.required'
      errors.page.push('app.errors.zipCode.required.notice');
    }
    else if (values.zipCode.length!=5 && values.zipCode.length!=10){
      errors.zipCode = 'app.errors.zipCode.invalidFormat'
      errors.page.push('app.errors.zipCode.invalidFormat.notice');
    }

    if (!values.birthdate) {
      errors.birthdate = 'app.errors.birthdate.required'
      errors.page.push('app.errors.birthdate.required.notice');
    }
    else if (!(ValidationService.isValidDate(values))){
      errors.birthdate = 'app.errors.birthdate.invalidFormat';
      errors.page.push('app.errors.birthdate.invalidFormat.notice');
    }
    else if (!ValidationService.isOfAge(values)){
      errors.birthdate ='app.errors.birthdate.notOfAge';
      errors.birthdateNotice ='app.errors.birthdate.notOfAge.notice';
    }
    else if (!ValidationService.isToOld(values)){
      errors.birthdate ='app.errors.birthdate.isToOld';
      errors.birthdateNotice ='app.errors.birthdate.isToOld.notice';
    }

    if (!values.ssn) {
      errors.ssn = 'app.errors.ssn.required'
      errors.page.push('app.errors.ssn.required.notice');
    }
    else if (values.ssn.length!=11) {
      errors.ssn = 'app.errors.ssn.invalidFormat'
      errors.page.push('app.errors.ssn.invalidFormat.notice');
    }

    return errors;
  }

  // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
  static isValidDate(values)
  {
    const dateString = values.birthdate;
    // First check for the pattern
    if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
      return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month == 0 || month > 12)
      return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  // Validates that the input string is a valid date formatted as ""mm/dd/yyyy" for the TAT form
  // The SF Repo converts this into YYYY-MM-DD
  static isValidTATDate(values)
  {
    const dateString = values.tatGoalDueDate;
    // First check for the pattern
    if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
      return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);
    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month == 0 || month > 12)
      return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  static isOfAge(values){
    const dateString = values.birthdate;
    var age = ValidationService.getAge(dateString);
    return age >= 18;
  }

  static getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  static isToOld(values){
    const dateString = values.birthdate;
    var age = ValidationService.getAge(dateString);
    return age <= 110;
  }

  static tatDueDateInThePast(values) {
    var today = new Date();
    var dueDate = new Date(values.tatGoalDueDate);
    if (today > dueDate) {
      return true;
    }
  }

  static interestRate(values) {
    const errors = {}
    errors.page=[]
    // console.log("Validation");
    // console.log(values.interestRate);
    if (values.interestRate == null) {
      // console.log("NULL");
      // console.log(values.interestRate);
      return true;
    }
    else if (values.interestRate == '') {
      // console.log("Blank");
      // console.log(values.interestRate);
      return true;
    }
    else if (values.interestRate != '') {
      if(!/^[0-9.]*$/.test(values.interestRate)) {
        errors.interestRate = 'app.errors.interestRate.notice'
        errors.page.push('app.errors.interestRate.notice');
      }
    }

    return errors;
  }



  static validateTakeActionToday(values){
    const errors = {}
    errors.page = []

    if (!values.tatGoalComment) {
      errors.tatGoalComment = 'app.errors.tatGoalComment.required'
    }
    if (!values.tatGoalDueDate){
        errors.tatGoalDueDate = 'app.errors.tatGoalDueDate.required'
    }
    else if (!(ValidationService.isValidTATDate(values))){
      errors.tatGoalDueDate = 'app.errors.tatGoalDueDate.invalidFormat';
      errors.page.push('app.errors.tatGoalDueDate.invalidFormat.notice');
    }
    else if (ValidationService.tatDueDateInThePast(values)) {
      errors.tatGoalDueDate = 'app.errors.tatGoalDueDate.inThePast';
      errors.page.push('app.errors.tatGoalDueDate.inThePast.notice');
    }
    if (!values.tatHowToAccomplish){
        errors.tatHowToAccomplish = 'app.errors.tatHowToAccomplish.required'
    }
    return errors
  }

  static validateClientStoriesForm(values){
    const errors = {}
    errors.page=[]

    if (!values.preferredName) {
      errors.preferredName = 'app.errors.preferredName.required'
      errors.page.push('app.errors.preferredName.required.notice');
    }

    if (!values.signature) {
      errors.signature = 'app.errors.signature.required'
      errors.page.push('app.errors.signature.required.notice');
    }

    if (!values.phone) {
      errors.phone = 'app.errors.mobilePhone.required'
      errors.page.push('app.errors.mobilePhone.required.notice');
    }

    else if( values.phone.length < 14 || values.phone.length != 16) {
        if (values.phone.charAt(0) == 1 && values.phone.length != 16) {
          errors.phone = 'app.errors.mobilePhone.invalidFormat'
          errors.page.push('app.errors.mobilePhone.invalidFormat.notice');
        }
        if (values.phone.charAt(0) != 1 && values.phone.length != 14) {
          errors.phone = 'app.errors.mobilePhone.invalidFormat'
          errors.page.push('app.errors.mobilePhone.invalidFormat.notice');
        }
    }

    return errors;
  }

  static validateFeedbackInput(values){
    const errors = {}
    errors.page=[]

    if (!values.feedbackDescription){
      errors.feedbackDescription = 'app.action-plan-feedback.comments.required'
      errors.page.push('app.action-plan-feedback.comments.required.notice');
    }

    return errors
  }

  static validateStandaloneCreditReportValues(values) {
    const errors = {}
    errors.page=[]

    if (!values.mobilePhone) {
      errors.mobilePhone = 'app.errors.mobilePhone.required'
      errors.page.push('app.errors.mobilePhone.required.notice');
    }

    else if( values.mobilePhone.length < 14 || values.mobilePhone.length != 16) {
      if (values.mobilePhone.charAt(0) == 1 && values.mobilePhone.length != 16) {
        errors.mobilePhone = 'app.errors.mobilePhone.invalidFormat'
        errors.page.push('app.errors.mobilePhone.invalidFormat.notice');
      }
      if (values.mobilePhone.charAt(0) != 1 && values.mobilePhone.length != 14) {
        errors.mobilePhone = 'app.errors.mobilePhone.invalidFormat'
        errors.page.push('app.errors.mobilePhone.invalidFormat.notice');
      }
    }

    if (!values.street) {
      errors.street = 'app.errors.street.required'
      errors.page.push('app.errors.street.required.notice');
    }

    if (!values.city) {
      errors.city = 'app.errors.city.required'
      errors.page.push('app.errors.city.required.notice');
    }

    if (!values.state) {
      errors.state = 'app.errors.state.required'
      errors.page.push('app.errors.state.required.notice');
    }

    if (!values.zipCode) {
      errors.zipCode = 'app.errors.zipCode.required'
      errors.page.push('app.errors.zipCode.required.notice');
    }
    else if (values.zipCode.length!=5 && values.zipCode.length!=10){
      errors.zipCode = 'app.errors.zipCode.invalidFormat'
      errors.page.push('app.errors.zipCode.invalidFormat.notice');
    }

    if (!values.birthdate) {
      errors.birthdate = 'app.errors.birthdate.required'
      errors.page.push('app.errors.birthdate.required.notice');
    }
    else if (!(ValidationService.isValidDate(values))){
      errors.birthdate = 'app.errors.birthdate.invalidFormat';
      errors.page.push('app.errors.birthdate.invalidFormat.notice');
    }
    else if (!ValidationService.isOfAge(values)){
      errors.birthdate ='app.errors.birthdate.notOfAge';
      errors.birthdateNotice ='app.errors.birthdate.notOfAge.notice';
    }
    else if (!ValidationService.isToOld(values)){
      errors.birthdate ='app.errors.birthdate.isToOld';
      errors.birthdateNotice ='app.errors.birthdate.isToOld.notice';
    }

    if (!values.ssn) {
      errors.ssn = 'app.errors.ssn.required'
      errors.page.push('app.errors.ssn.required.notice');
    }
    else if (values.ssn.length!=11) {
      errors.ssn = 'app.errors.ssn.invalidFormat'
      errors.page.push('app.errors.ssn.invalidFormat.notice');
    }


    return errors
  }

  static files(values) {
    const errors = {}

    if(!values.name){
      errors.name = 'app.errors.customers.files.file-name-required'
    }
    else if (!ValidationService.isValidFileType(values.name)) {
      errors.name = 'app.errors.customers.files.file-extension'
    }

    return errors
  }

  static isValidFileType(value){
    return (/^.*\.(jpg|JPG|png|PNG|gif|GIF|doc|DOC|docx|DOCX|pdf|PDF)$/.test(value));
  }

}
