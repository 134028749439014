import React from 'react';
import Clipboard from "./clipboard";
import ChartPie from './chart-pie'
import Tachometer from './tachometer'
import Calendar from "./calendar";
import MoneyBill from './money-bill'
import Globe from './globe-americas'
import Cog from './cog'
import SpeechBubble from "./bubble-speech";
import SignOut from './sign-out'
import SmallLogo from './small-logo'
import DebtPieChart from './debt-pie-chart'
import Plus from './plus'
import Minus from './minus'
import ExclamationTriangle from './exclamation-triangle'
import ChevronRight from './chevron-right'
import BudgetJarEmpty from './budget-jar-empty'
import BudgetJarSome from './budget-jar-some'
import BudgetJarMore from './budget-jar-more'
import ToDoPlant from "./to-do-plant";
import ToDoPlantMobile from "./to-do-plant-mobile";
import AchievedPlantMobile from "./achieved-plant-mobile";
import AchievedPlantDesktop from "./achieved-plant-desktop";
import Facebook from "./facebook";
import X from "./x";
import LinkedIn from "./linkedin";
import InterestRateArrow from "./interest-rate-arrow";
import TPFullLogo from "./tp-full-logo";
import TPlLogo from "./tp-logo";
import TrustEdLogo from "./trust-ed-logo";
import FinancialEmpowermentLogo from "./financial-empowerment-logo";
import TPCLogo from "./tpc-logo";
import TPCAvatar from "./tpc-avatar";
import TPCPieChartBelow580 from "./tpc-credit-score-range-below-580";
import TPCPieChart580To669 from "./tpc-credit-score-range-580-669";
import TPCPieChart740To799 from "./tpc-credit-score-range-740-799";
import TPCPieChart800Plus from "./tpc-credit-score-range-800-plus";
import TPCPieChart670To739 from "./tpc-credit-score-range-670-739";
import TPCPieChartActive from "./tpc-credit-score-range-chart-active";
import Covid19 from "./covid19";
import SaverLifeLogo from "./saverlife";
import ShockedEmoji from "./shocked-emoji";
import SadEmoji from "./sad-emoji";
import OverwhelmedEmoji from "./overwhelmed-emoji";
import NeutralEmoji from "./neutral-emoji";
import FineEmoji from "./fine-emoji";
import CovidYellow from "./covid-yellow";
import CovidBrown from "./covid-brown";
import CovidPeach from "./covid-peach";
import CovidTan from "./covid-tan";
import WorriedEmoji from "./worried-emoji";
import NervousEmoji from "./nervous-emoji";
import SurprisedEmoji from "./surprised-emoji";
import ScaredEmoji from "./scared-emoji";
import WorksCall from "./works-call";
import WorksListen from "./works-listen";
import WorksContact from "./works-contact";
import QuizExclaim from "./quiz-exclaim";
import QuizCheck from "./quiz-check";
import ProgressBigCheck from "./progress-bigcheck";
import ProgressLittleCheck from "./progress-littlecheck";
import OnlineTools from "./online-tools";
import HowItWorksPhone from "./how-it-works-phone";
import HowItWorksGoals from "./how-it-works-goals";
import HowItWorksTalkWithCoach from "./how-it-works-talk-with-coach";
import HowItWorksConfidential from "./how-it-works-confidential";
import ArrowRed from "./arrow-red";
import SendArrow from "./send-arrow";
import Celebrate from "./celebrate";
import TrustEd from "./trust-ed";
import MyAccount from "./my-account";
import Contracts from "./contracts";
import Reports from "./reports";
import Onboarding from "./onboarding";
import CustomerHome from "./customer-home";
import Messaging from "./messaging";
import Faq from "./faq";
import Checkmark from "./checkmark";
import MessagingInbox from "./messaging-inbox";
import MessagingSent from "./messaging-sent";
import MessagingNew from "./messaging-new";
import ReplyArrow from "./reply-arrow"
import MessagingPlain from "./messaging-plain";
import PiggyBank from "./piggy-bank";
import BluePiggyBank from "./blue-piggy-bank";
import Award from "./award";
import DclSaveMoney from "./dcl-save-money";
import DclCoaching from "./dcl-coaching";
import DclOneBill from "./dcl-one-bill";
import DclSurveyStart from "./dcl-survey-start";
import DclSurveyStartMobile from "./dcl-survey-start-mobile";
import DclNotFit from "./dcl-not-fit";
import DclFit from "./dcl-fit";
import DclProducts from "./dcl-products";
import DclTestimonialQuotation from "./dcl-testimonial-quotation";
import DclImpact1 from "./dcl-impact-1";
import DclImpact2 from "./dcl-impact-2";
import DclImpact3 from "./dcl-impact-3";
import DclDashboard from "./dcl-dashboard";
import DclSurveyStartSpanish from "./dcl-survey-start-spanish";
import DclSurveyStartMobileSpanish from "./dcl-survey-start-mobile-spanish";
import MagnifyingGlass from "./magnifying-glass";
import Bank from "./bank";
import Scan from "./scan";
import Shield from "./shield";

const Icon = props => {
  switch(props.name) {
    case "tp-full-logo":
      return <TPFullLogo {...props}/> ;
    case "tp-logo":
      return <TPlLogo {...props} />;
    case "small-logo":
      return <SmallLogo {...props} />;
    case "clipboard":
      return <Clipboard {...props} />;
    case "chartpie":
      return <ChartPie {...props} />;
    case "tachometer":
      return <Tachometer {...props} />;
    case "calendar":
      return <Calendar {...props}/>;
    case "money-bill":
      return <MoneyBill {...props} />;
    case "globe-americas":
      return <Globe {...props} />;
    case "cog":
      return <Cog {...props} />;
    case "speech-bubble":
      return <SpeechBubble {...props} />;
    case "sign-out":
      return <SignOut {...props} />;
    case "debt-pie-chart":
      return <DebtPieChart {...props} />;
    case "plus":
      return <Plus {...props} />;
    case "minus":
      return <Minus {...props} />;
    case "exclamation-triangle":
      return <ExclamationTriangle {...props} />;
    case "interest-rate-arrow":
      return <InterestRateArrow {...props} />;
    case "chevron-right":
      return <ChevronRight {...props} />;
    case "budget-save":
      return <BudgetSave {...props} />;
    case "budget-plus-active":
      return <BudgetPlusActive {...props} />;
    case "budget-minus-active":
      return <BudgetMinusActive {...props} />;
    case "budget-minus-inactive":
      return <BudgetMinusInactive {...props} />;
    case "budget-jar-empty":
      return <BudgetJarEmpty {...props} />;
    case "budget-jar-some":
      return <BudgetJarSome {...props} />;
    case "budget-jar-more":
      return <BudgetJarMore {...props} />;
    case "to-do-plant":
      return <ToDoPlant {...props} />;
    case "to-do-plant-mobile":
      return <ToDoPlantMobile {...props} />;
    case "achieved-plant-mobile":
      return <AchievedPlantMobile {...props} />;
    case "achieved-plant-desktop":
      return <AchievedPlantDesktop {...props} />;
    case "facebook":
      return <Facebook {...props} />;
    case "x":
      return <X {...props} />;
    case "linkedin":
      return <LinkedIn {...props} />;
    case "tpc-logo":
      return <TPCLogo {...props} />;
    case "financial-empowerment-logo":
      return <FinancialEmpowermentLogo {...props} />;
    case "trust-ed-logo":
      return <TrustEdLogo {...props} />;
    case "tpc-avatar":
      return <TPCAvatar {...props} />;
    case "tpc-pie-chart-below-580":
      return <TPCPieChartBelow580 {...props} />;
    case "tpc-pie-chart-580-669":
      return <TPCPieChart580To669 {...props} />;
    case "tpc-pie-chart-670-739":
      return <TPCPieChart670To739 {...props} />;
    case "tpc-pie-chart-740-799":
      return <TPCPieChart740To799 {...props} />;
    case "tpc-pie-chart-800-plus":
      return <TPCPieChart800Plus {...props} />;
    case "tpc-pie-chart-active":
      return <TPCPieChartActive {...props} />;
    case "covid-19":
      return <Covid19 {...props} />;
    case "saverlife-logo":
      return <SaverLifeLogo {...props} />;
    case "shocked-emoji":
      return <ShockedEmoji {...props} />;
    case "sad-emoji":
      return <SadEmoji {...props} />;
    case "overwhelmed-emoji":
      return <OverwhelmedEmoji {...props} />;
    case "neutral-emoji":
      return <NeutralEmoji {...props} />;
    case "fine-emoji":
      return <FineEmoji {...props} />;
    case "covid-yellow":
      return <CovidYellow {...props} />;
    case "covid-brown":
      return <CovidBrown {...props} />;
    case "covid-peach":
      return <CovidPeach {...props} />;
    case "covid-tan":
      return <CovidTan {...props} />;
    case "worried-emoji":
      return <WorriedEmoji {...props} />;
    case "nervous-emoji":
      return <NervousEmoji {...props} />;
    case "surprised-emoji":
      return <SurprisedEmoji {...props} />;
    case "scared-emoji":
      return <ScaredEmoji {...props} />;
    case "works-call":
      return <WorksCall {...props} />;
    case "works-listen":
      return <WorksListen {...props} />;
    case "works-contact":
      return <WorksContact {...props} />;
    case "quiz-exclaim":
      return <QuizExclaim {...props} />;
    case "quiz-check":
      return <QuizCheck {...props} />;
    case "progress-bigcheck":
      return <ProgressBigCheck {...props} />;
    case "progress-littlecheck":
      return <ProgressLittleCheck {...props} />;
    case "online-tools":
      return <OnlineTools {...props} />;
    case "how-it-works-phone":
      return <HowItWorksPhone{...props} />;
    case "how-it-works-goals":
      return <HowItWorksGoals{...props} />;
    case "how-it-works-talk-with-coach":
      return <HowItWorksTalkWithCoach{...props} />;
    case "how-it-works-confidential":
      return <HowItWorksConfidential{...props} />;
    case "arrow-red":
      return <ArrowRed{...props} />;
    case "send-arrow":
      return <SendArrow{...props} />;
    case "celebrate":
      return <Celebrate{...props} />;
    case "trust-ed":
      return <TrustEd{...props} />;
    case "my-account":
      return <MyAccount{...props} />;
    case "contracts":
      return <Contracts{...props} />;
    case "reports":
      return <Reports{...props} />;
    case "onboarding":
      return <Onboarding{...props} />;
    case "customer-home":
      return <CustomerHome{...props} />;
    case "messaging":
      return <Messaging{...props} />;
    case "faq":
      return <Faq{...props} />;
    case "checkmark":
      return <Checkmark{...props} />;
    case "messaging-inbox":
      return <MessagingInbox{...props} />;
    case "messaging-sent":
      return <MessagingSent{...props} />;
    case "messaging-new":
      return <MessagingNew{...props} />;
    case "reply-arrow":
      return <ReplyArrow{...props} />;
    case "messaging-plain":
      return <MessagingPlain{...props} />;
    case "piggy-bank":
      return <PiggyBank{...props} />;
    case "blue-piggy-bank":
      return <BluePiggyBank{...props} />;
    case "award":
      return <Award{...props} />;
    case "dcl-save-money":
      return <DclSaveMoney{...props} />;
    case "dcl-one-bill":
      return <DclOneBill{...props} />;
    case "dcl-coaching":
      return <DclCoaching{...props} />;
    case "dcl-products":
      return <DclProducts{...props} />;
    case "dcl-survey-start":
      return <DclSurveyStart{...props} />;
    case "dcl-survey-start-mobile":
      return <DclSurveyStartMobile{...props} />;
    case "dcl-not-fit":
      return <DclNotFit{...props} />;
    case "dcl-fit":
      return <DclFit{...props} />;
    case "dcl-testimonial-quotation":
      return <DclTestimonialQuotation{...props} />;
    case "dcl-impact-1":
      return <DclImpact1{...props} />;
    case "dcl-impact-2":
      return <DclImpact2{...props} />;
    case "dcl-impact-3":
      return <DclImpact3{...props} />;
    case "dcl-dashboard":
      return <DclDashboard{...props} />;
    case "dcl-survey-start-spanish":
      return <DclSurveyStartSpanish{...props} />;
    case "dcl-survey-start-mobile-spanish":
      return <DclSurveyStartMobileSpanish{...props} />;
    case "magnifying-glass":
      return <MagnifyingGlass{...props} />;
    case "bank":
      return <Bank{...props} />;
    case "scan":
      return <Scan{...props} />;
    case "shield":
      return <Shield{...props} />;
    default:
      return <div />;
  }
}

export default Icon;
