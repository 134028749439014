import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CalendlyWidgetHelper from "../helpers/CalendlyWidgetHelper";
import {useDispatch, useSelector} from "react-redux";
import {calendlyEvent} from "../../actions/calendly-actions";
import {verifyToken} from "../../actions/login-actions";
import {useHistory, useLocation} from "react-router-dom";
import ActionPlanNav from "./action-plan-nav";
import { setActiveCounselor} from "../../actions/counselor-actions";
import {getBookedAppointments, getCompletedAttendedAppointments} from "../../actions/calendly-actions";

export default function ActionPlanAppointmentSchedule(props) {

  const locale = useSelector((state) => state.locale)
  const login = useSelector((state) => state.login)
  const registration = useSelector((state) => state.registration)
  const activeCounselor = useSelector((state) =>state.activeCounselor)
  const englishCounselors = useSelector(state => state.englishCounselors)
  const bookedAppointments = useSelector((state) => state.bookedAppointments)

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (login === null && registration === null) {
      console.log("ActionPlanAppointmentSchedule useEffect")
      console.log(location)
      const string = location.pathname
      const schedule = 'appointment-schedule'
      const select = 'select-appointment'
      if (string.includes(schedule) || string.includes(select)) {
        console.log("to AP with referral")
        history.push(`/welcome?referral=${location.pathname}`)
      }
    }
  }, [login, registration])

  useEffect(()=>{
            if (bookedAppointments.length > 0) {
                dispatch(setActiveCounselor(getCounselor(bookedAppointments[0].coach, englishCounselors)))
            }
    }, [bookedAppointments]);

  const onEventScheduled = (event) => {
    dispatch(calendlyEvent(event.data.payload.event.uri, event.data.payload.invitee.uri))
    history.push("/action-plan/sessions/appointment-confirmation")
    dispatch(verifyToken(login))
  };

  const goToPickCoach = () => {
    history.push("/action-plan/sessions/pick-your-coach")
    dispatch(verifyToken(login))
  };



  function getCounselor(contactId, counselors) {
        let counselor = null;
        if (counselors) {
            for (let i=0; i<counselors.length; i++) {
                let counselorTmp = counselors[i];
                if (counselorTmp.contactId === contactId) {
                    counselor = counselorTmp;
                }
            }
        }
        return counselor;
    }

  return (

        <div id='ap-sessions'>
        <ActionPlanNav />
          { login !== null && activeCounselor !== null ?
            <div id='action-plan-content'>
              <main>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                  <div className='action-plan-title-background-gradient'></div>
                  <h1 className='action-plan-title margin-bottom-8-px'>
                    <FormattedMessage id='app.action-plan-sessions.page-title'/>
                  </h1>
                </div>
                <div className='action-plan-subtitle margin-bottom-24-px'>
                  <FormattedMessage id='app.appointment.title'/>
                </div>
                <div className='pick-a-coach-change-coach'>
                  <button onClick={()=>goToPickCoach()}>
                    <div className='pick-a-coach-back-wrapper'>
                      <div className='pick-a-coach-back-arrow'>
                        <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                      </div>
                      <div className='pick-a-coach-back-text'>
                        <FormattedMessage id='app.common.change-coach.button'/>
                      </div>
                    </div>
                  </button>
                </div>

                <CalendlyWidgetHelper locale={locale}
                                      counselor={activeCounselor}
                                      registration={registration}
                                      onEventScheduled={onEventScheduled}
                />
              </main>
            </div>
          :
          <div>YUCK</div>
        }
      </div>


      )

};