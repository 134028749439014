import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import FooterActionPlan from '../footer-action-plan'
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions";
import AppointmentDetail from "../appointment-detail";
import {
    setActiveCounselor
} from "../../actions/counselor-actions";
import ActionPlanNav from "./action-plan-nav";
import {useHistory, useLocation} from "react-router-dom";
import {getBookedAppointments, getCompletedAttendedAppointments} from "../../actions/calendly-actions";
import {getEmployerFromContactId} from "../../actions/employer-actions";
import {employerLoadedForNav} from "../../actions/nav-actions";
import {doesContactHaveCreditReportOnFile} from "../../actions/trust-plus-credit-actions";
import {displayLinkMoneyModal} from "../../actions/link-money-actions";



export default function ActionPlanSessions(props) {

    const activeCounselor = useSelector((state) =>state.activeCounselor)
    const login = useSelector((state) => state.login)
    const registration = useSelector((state) => state.registration)
    const bookedAppointments = useSelector((state) => state.bookedAppointments)
    const completedAttendedAppointments = useSelector((state) => state.completedAttendedAppointments)
    const englishCounselors = useSelector(state => state.englishCounselors)
    const currentCalendlyConfirmationPath = useSelector(state => state.currentCalendlyConfirmationPath)

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [upcomingAppt, setUpcomingAppt] = useState([]);
    const [previousAppt, setPreviousAppt] = useState([]);

    useEffect(()=>{
        window.scrollTo(0, 0)
        dispatch(displayLinkMoneyModal(false))
    })

    useEffect(() => {
        if (login === null && registration === null) {
          console.log("ActionPlanSessions useEffect")
          console.log(location)
          const string = location.pathname
          const schedule = 'appointment-schedule'
          const select = 'select-appointment'
          const session = 'sessions'
          if (string.includes(schedule) || string.includes(select) || string.includes(session)) {
            console.log("to AP with referral")
            history.push(`/welcome?referral=${location.pathname}`)
          }
        }
      }, [login, registration])

    useEffect(()=>{
        if (currentCalendlyConfirmationPath === "/action-plan/sessions" && bookedAppointments.length === 0) {
            dispatch(getBookedAppointments())
        }
    }, [bookedAppointments]);

    useEffect(()=>{
            if (bookedAppointments.length > 0) {
                dispatch(setActiveCounselor(getCounselor(bookedAppointments[0].coach, englishCounselors)))
            }
    }, [bookedAppointments]);

    useEffect(()=>{
        if (login) {
            dispatch(getBookedAppointments()).then(() => {
                dispatch(getCompletedAttendedAppointments())
            })
        }
    }, [])

    useEffect(()=>{
        if (activeCounselor) {
            setUpcomingAppt(setupApptDetailBooked())
        }
    }, [bookedAppointments, activeCounselor]);

    useEffect(()=>{
        setPreviousAppt(setupApptDetailCompletedAttended())
    }, [completedAttendedAppointments]);

    function getCounselor(contactId, counselors) {
        let counselor = null;
        if (counselors) {
            for (let i=0; i<counselors.length; i++) {
                let counselorTmp = counselors[i];
                if (counselorTmp.contactId === contactId) {
                    counselor = counselorTmp;
                }
            }
        }
        return counselor;
    }


    const goToSchedule = () => {
        if (activeCounselor !== null) {
            history.push("/action-plan/sessions/appointment-schedule")
        }
        else if (completedAttendedAppointments[0] && completedAttendedAppointments.length === 1) {
            history.push("/action-plan/sessions/pick-your-coach")
        }
        else {
            history.push("/action-plan/sessions/select-appointment")
        }
        dispatch(verifyToken(login))
    }

    const setupApptDetailBooked = () => {
        let appts = []
        // LM removed for release 105. Amauris is seeing double appointments. We will address with UI
        //if (registration.newAppointment) {
        //    appts.push(apptDetail(registration.newAppointment))
        //}
        bookedAppointments && bookedAppointments.map(appt => {
           appts.push(apptDetail(appt))
        })
        return appts;
    }

    const setupApptDetailCompletedAttended = () => {
        let appts = []
        completedAttendedAppointments && completedAttendedAppointments.map(appt => {
            appts.push(apptDetail(appt, true))
        })
        return appts;
    }

    function apptDetail(appt, renderFromDashboard=false) {
        return <AppointmentDetail appt={appt}
                                  renderFromDashboard={renderFromDashboard}
                                  activeCurrentlyWorkingCounselors={englishCounselors}
        />
    }

    return (
        <div id='ap-sessions'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                        <FormattedMessage id='app.action-plan-sessions.page-title'/>
                    </h1>
                </div>
                <div className='sessions-page-container'>
                    <div className='sessions-container upcoming-sessions-container margin-bottom-48-px'>
                        <div className='action-plan-sub-heading margin-bottom-8-px'>
                            <FormattedMessage id='app.action-plan-sessions.upcoming'/>
                        </div>
                        {
                            upcomingAppt.length > 0 ? '' :
                                <div>
                                    <div className='sessions-no-upcoming margin-bottom-24-px'>
                                        <FormattedMessage id='app.action-plan-sessions.no-upcoming'/>
                                    </div>
                                </div>
                        }
                        <div className='session-wrapper upcoming-session-wrapper'>
                            {upcomingAppt}
                        </div>

                        <div className='action-plan-divide upcoming-session-divide'></div>

                        <div>
                            <div className='submit-button-round margin-bottom-24-px margin-top-24-px'>
                                <button id='submit'
                                        type={'submit'}
                                        onClick={()=>goToSchedule()}
                                        className='btn btn-primary btn-block btn-lg'>
                                    <FormattedMessage id='app.action-plan-nav.button.schedule'/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='sessions-container'>
                        <div className='action-plan-sub-heading margin-bottom-8-px'>
                            <FormattedMessage id='app.action-plan-sessions.previous'/>
                        </div>
                        {
                            previousAppt.length > 0 ? '' :
                                <div>
                                    <div className='sessions-no-previous margin-bottom-24-px'>
                                        <FormattedMessage id='app.action-plan-sessions.no-previous'/>
                                    </div>
                                </div>
                        }
                        <div className='session-wrapper previous-session-wrapper'>
                            {previousAppt}
                        </div>
                    </div>
                </div>

                <FooterActionPlan />
            </div>
        </div>)

};
