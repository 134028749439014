import {
  UPDATE_REGISTRATION,
  GET_REGISTRATION,
  CREDIT_REPORT_OPT_OUT, UPDATE_SM_LINK,
} from '../actions/registration-actions'
import {SUBMIT_REGISTRATION} from '../actions/calendly-actions'
import {
  CHECK_CONTACT_RECORD,
  CREATE_OR_UPDATE_CONTACT,
  RESET_REGISTRATION,
  UPDATE_REGISTRATION_INFORMATION
} from "../actions/b-flow-actions";
import { SAML_RELAY } from "../actions/login-actions";
import { UPDATE_CONTACT_ID } from "../actions/registration-actions";

const registration = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_REGISTRATION:
      return action.registration
    case UPDATE_REGISTRATION:
      return action.registration
    case GET_REGISTRATION:
      return action.registration
    case CREDIT_REPORT_OPT_OUT:
      return action.registration
    case CREATE_OR_UPDATE_CONTACT:
      return action.registration
    case CHECK_CONTACT_RECORD:
      return action.registration
    case RESET_REGISTRATION:
      return action.registration
    case UPDATE_REGISTRATION_INFORMATION:
      return action.registration
    case SAML_RELAY:
      return action.registration
    case UPDATE_CONTACT_ID:
      return {
        ...state,
          id: action.registration.id
      }
    default:
      return state
  }
}

export default registration
