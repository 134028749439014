import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import {financialSnapshotDownloaded, getLinkedAccounts} from "../../actions/link-money-actions";
import {useHistory} from "react-router-dom";
import FormattedMessage from "../helpers/FormattedMessage";
import Icon from "../../../resources/icons";

export default function ActionPlanLinkMoneyAccounts(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)
  const linkMoneyFinancialSnapshot = useSelector(state => state.linkMoneyFinancialSnapshot)
  const snapshotDownloaded = useSelector(state => state.financialSnapshotDownloaded)

  const [pdfConsent, setPdfConsent] = useState(true);

  useEffect(() => {
    dispatch(getLinkedAccounts())
  }, [])

  useEffect(() => {
    if (linkMoneyAccounts.length < 1) {
      history.push('/action-plan/link-money/gateway')
    }
  })

  useEffect(() => {
    linkMoneyAccounts && linkMoneyAccounts.map(account => {
      if (new Date(account.createdAt) < new Date(2024, 10, 17)) {
        setPdfConsent(false)
      }
    })
  })

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  function downloadSnapshot() {
    const file = linkMoneyFinancialSnapshot[linkMoneyFinancialSnapshot.length - 1];
    const linkSource = `data:application/pdf;base64,${file.versionData}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = file.title;
    downloadLink.click();
    dispatch(financialSnapshotDownloaded(true));
  }

  return (
    <div id='action-plan-link-money-accounts'>
      <ActionPlanNav />
      <div id='action-plan-content'>
        <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <h1 className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.action-plan-link-money-accounts.header'/>
            </h1>
        </div>

        <div className='link-money-accounts'>
          {linkMoneyFinancialSnapshot.length > 0 && pdfConsent == true ?
              <div className='link-money-financial-snapshot'>
                <div className='link-money-financial-snapshot-body'>
                  <div className="link-money-financial-snapshot-title-container">
                    <div className='link-money-financial-snapshot-title'>
                      <FormattedMessage id='app.action-plan-link-money-accounts.financial-snapshot.title'/>
                    </div>
                    <div className="link-money-financial-snapshot-image-mobile">
                      <Icon name="magnifying-glass"/>
                    </div>
                  </div>
                  <div className='link-money-financial-snapshot-text'>
                    <div className='link-money-accounts-description'>
                      <FormattedMessage
                          id='app.action-plan-link-money-accounts.linked-accounts.financial-snapshot.description-1'/>
                    </div>
                    <div className='link-money-accounts-description'>
                      <button id='link-money-landing-btn'
                              type={'button'}
                              className="btn btn-primary btn-block btn-lg link-money-button"
                              onClick={() => downloadSnapshot()}>
                        <FormattedMessage id='app.action-plan-link-money-accounts.financial-snapshot.button'/>
                      </button>
                    </div>
                    {
                      snapshotDownloaded == true ?
                          <div className='link-money-accounts-description-center'>
                            <div className='link-money-financial-snapshot-header'>
                              <FormattedMessage
                                  id='app.action-plan-link-money-accounts.linked-accounts.financial-snapshot.header'/>
                            </div>
                            <div>
                              <FormattedMessage
                                  id='app.action-plan-link-money-accounts.linked-accounts.financial-snapshot.description-2'/>
                            </div>
                          </div>
                          : ''
                    }
                  </div>
                </div>
                <div className="link-money-financial-snapshot-image">
                  <Icon name="magnifying-glass"/>
                </div>
              </div>
              : ''}

          <div className='link-money-accounts-container'>
          <div className="link-money-accounts-title">
              <FormattedMessage id='app.action-plan-link-money-accounts.title'/>
            </div>
            <div className='link-money-accounts-description'>
              <FormattedMessage id='app.action-plan-link-money-accounts.linked-accounts.description-1'/>
            </div>
            <div className='link-money-accounts-description'>
              <FormattedMessage id='app.action-plan-link-money-accounts.linked-accounts.description-2'/>
              <a onClick={() => history.push('/action-plan/link-money/gateway')}>
                <FormattedMessage id='app.action-plan-link-money-accounts.linked-accounts.description.link'/>
              </a>
              ?
            </div>
            <div className='link-money-accounts-break'/>

            {linkMoneyAccounts.map((item) => {
              return <div className='link-money-account-row'>
                <div className='link-money-account-row-logo'>
                  <div className='link-money-img-wrapper'>
                    <img src={item.logoUrl} width="auto" height="auto" alt={item.bankName}/>
                  </div>
                  <div className='account-bank'>
                    {item.bankName}
                  </div>
                </div>
                <div className='link-money-account-row-name'>
                  <div className=''>
                    <div className='account-type'>
                      {item.accountName}
                    </div>
                    <div>
                      Account: x{item.lastFour}
                    </div>
                  </div>
                </div>
              </div>
            })}

          </div>

          <div className='link-money-accounts-container'>
            <div>
              <div className='link-money-accounts-title margin-bottom-24-px'>
                <FormattedMessage id='app.action-plan-link-money-accounts.link-more.title'/>
              </div>
              <div className='link-money-accounts-link-more-description margin-bottom-24-px'>
                <FormattedMessage id='app.action-plan-link-money-accounts.link-more.description'/>
              </div>
              <div className='link-money-accounts-button'>
                <button id='link-money-landing-btn'
                        type={'button'}
                        className="btn btn-primary btn-block btn-lg"
                        onClick={(e) => history.push('/action-plan/link-money/gateway')}>
                  <FormattedMessage id='app.action-plan-link-money.button.link-accounts'/>
                </button>
              </div>
            </div>
          </div>

        </div>

        <FooterActionPlan/>
      </div>
    </div>
  )
}

