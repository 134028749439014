export const CHANGE_LOCALE = 'CHANGE_LOCALE'
export const CHANGE_MSG_FILE = 'CHANGE_MSG_FILE'

export function toggleLocale(currentLocale) {
  return (dispatch, getState) => {

    const {employer} = getState()

    let newLocale = currentLocale === 'en-US' ? 'es-US' : 'en-US'
    let newMsgFile = newLocale


    dispatch({
      type: CHANGE_LOCALE,
      locale: newLocale,
    })

    dispatch({
      type: CHANGE_MSG_FILE,
      msgFile: newMsgFile
    })
  }
}

export function updateMsgFile() {
  return (dispatch, getState) => {

    const {employer, locale, msgFile} = getState()

    let newMsgFile = employer && locale === 'en-US' ? "en-US" : "es-US"

    if (msgFile !== newMsgFile) {
      dispatch({
        type: CHANGE_MSG_FILE,
        msgFile: newMsgFile
      })
    }
  }
}
