import React, {useEffect, useState} from 'react'

import FormattedMessage from '../helpers/FormattedMessage'
import Field from '../helpers/Field'
import HeaderContainerAP from '../../containers/header-container-ap'
import FooterContainerAp from '../../containers/footer-container-ap'
import FormUserError from "../form-user-error";
import FormError from "../form-error";
import {Link} from "react-router-dom";
import {reduxForm} from "redux-form";
import ValidationService from "../../services/validation-service";
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {verifyEnrollmentCode, createEnrollment} from "../../actions/enrollment-actions";
import ResendCodeModal from "../modals/resend-code-modal";


const renderField = ({input, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type} maxLength={6}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

const rethrowIfFailed = error => {
    if (error && error._error) {
        throw new Error(error);
    }
}

function BFlowTempCodeForm(props){

    const [isProcessing, setIsProcessing] = useState(false)
    const [fatalFormError, setFatalFormError] = useState(null)
    const [displayResendMessage, setDisplayResendMessage] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)

    const login = useSelector(state => state.login);
    const registration = useSelector(state => state.registration);

    const dispatch = useDispatch();

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid ||
            isProcessing
    }

    useEffect(() => {
        if(registration === null && login === null){
            props.history.push(`/welcome`)
        } else if (login != null && login !== '') {
            if (login.nextPage === 'CREATEPASSWORD') {
                props.history.push('/make-a-password')
            } else if (login.nextPage  === 'PASSWORD') {
                props.history.push('/enter-your-password')
            } else if (login.nextPage === null) {
                props.history.push('/action-plan')
            }
        }

        if (props.error && props.error === 'app.b-flow.temp-code-form.unsuccessful') {
            let err = props.error
            setFatalFormError(err);
            setIsProcessing(false);
            document.getElementsByName('singleUseCode')[0].style.border = 'solid 3px #EE3E41';
        }
    })

    useEffect(() => {
        setInterval(() => {
            if (login.codeExpiration != null) {
                if (new Date() > new Date(login.codeExpiration)) {
                    setDisplayModal(true)
                }
            }
        }, 60000);
    });

    const resend = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();

        ensureResend()
            .then(() => setDisplayResendMessage(true))

        props.history.push('/resend-temp-code');
    }

    const ensureResend = () => {
        return props.ensureResend
            ? Promise.resolve()
            : dispatch(createEnrollment()).then(rethrowIfFailed)
    }

    const {intl, formSyncErrors} = props;

    return (
        <div>
            {displayModal ? <div className="back-drop-modal"><ResendCodeModal resend={(e) => resend(e)} /></div> : <div/>}
        <div className='b-flow b-flow-background'>
            <HeaderContainerAP/>
            <div className='b-flow-body'>
                <div className='container-ap'>
                    {!displayResendMessage && registration ?
                        <h1>
                            <FormattedMessage id='app.b-flow.temp-code-form.title'/>
                            {registration.firstName}!
                            <br/>
                            <FormattedMessage id='app.b-flow.temp-code-form.instruction-1'/>
                        </h1>
                        :
                        <h1>
                        <FormattedMessage id='app.b-flow.temp-code-form.instruction-1'/>
                    </h1>}
                    <form onSubmit={props.handleSubmit}>
                        <div className='temp-code'>
                            <Field name='singleUseCode' type='tel' component={renderField}/>
                        </div>

                        <div className='margin-bottom-32-px'>
                            <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                            <FormError error={fatalFormError}/>
                        </div>

                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-red'
                                    disabled={isSubmitDisabled(props)}>
                                <FormattedMessage id='app.b-flow.temp-code-form.continue.button'/>
                            </button>
                        </div>

                        {/*TODO: add change mobile phone functionality */}
                        {/*{updateMobilePhone ?*/}
                        {/*    <div/> :*/}
                        {/*    <div className='text-ap additional-links'>*/}
                        {/*        <Link to="/resend-temp-code" className='back-link' onClick={(e) => resend(e)}*/}
                        {/*              id='resend'>*/}
                        {/*            <FormattedMessage id='app.b-flow.temp-code-form.instruction-2'/>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {displayResendMessage ?
                            <div/> :
                            <div className='text-ap additional-links'>
                                <Link to="/resend-temp-code" className='back-link' onClick={(e) => resend(e)}
                                      id='resend'>
                                    <FormattedMessage id='app.b-flow.temp-code-form.instruction-3'/>
                                </Link>
                            </div>
                        }
                    </form>
                </div>
                <FooterContainerAp/>
            </div>
        </div>
        </div>)
}

function goToNextPage(props) {
    props.history.push('/action-plan');
}

export default reduxForm({
    form: 'BFlowTempCodeForm',
    validate: ValidationService.verifyTempcode,
    onSubmit: (values, dispatch, props) => {
        console.log(values)
        return dispatch(verifyEnrollmentCode(values)).then(() => {
            goToNextPage(props)
        })
    },
    destroyOnUnmount: false
})(injectIntl(BFlowTempCodeForm))