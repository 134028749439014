import React from 'react'

export const ShieldIcon = ({
                                       style = {},
                                       fill = '#fff',
                                       width = '100%',
                                       className = '',
                                       height = '100%',
                                       viewBox = '0 0 155 154',
                                       title = '',
                                       desc = '',
                                     }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    aria-labelledby="title  desc"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M77,0h1c42.53,0,77,34.47,77,77h0c0,42.53-34.47,77-77,77h-1C34.47,154,0,119.53,0,77h0C0,34.47,34.47,0,77,0Z" fill="#003057"/>
      <path d="M43.02,55.24l34.48-13.39,34.5,13.39c.18,7.82-1.24,19.21-6.07,30.33-5.08,11.66-13.85,22.93-28.43,29.61-14.57-6.67-23.34-17.93-28.41-29.61-4.83-11.12-6.25-22.51-6.07-30.33ZM120.54,52.91l-.22-3.68-3.45-1.35-37.29-14.46-2.08-.81-2.08.81-37.29,14.46-3.43,1.35-.22,3.68c-.52,8.97.88,22.69,6.7,36.1,5.88,13.51,16.35,26.95,34.03,34.61l2.28.99,2.28-.99c17.68-7.65,28.16-21.08,34.03-34.61,5.82-13.42,7.22-27.13,6.7-36.1h.02ZM87.56,69.98c0-5.55-4.51-10.06-10.06-10.06s-10.06,4.51-10.06,10.06c0,4.01,2.35,7.47,5.75,9.09v16.78h8.62v-16.78c3.4-1.62,5.75-5.09,5.75-9.09Z" fill="#fff"/>
    </g>

  </svg>;

export default ShieldIcon