import React from 'react'

export const HowItWorksStep4 = ({
                                  style = {},
                                  fill = '#000000',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 94.6 90',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_2-2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1-2">
        <path d="M55.5,90h32.6c3.6,0,6.5-2.8,6.5-6.2V6.2c0-3.5-2.9-6.2-6.5-6.2h-32.6c-3.6,0-6.5,2.8-6.5,6.2v77.5c0,3.4,2.9,6.2,6.5,6.2h0v.1Z" fill="#003057"/>
        <path d="M55.9,2.9h4.7c-.2.2-.2.5-.2.8v1c0,.9.8,1.6,1.7,1.6h19.4c.9,0,1.6-.7,1.6-1.6v-1c0-.3,0-.6-.2-.8h4.7c2.6,0,4.7,2,4.7,4.6v75.4c0,2.5-2.1,4.6-4.7,4.6h-31.6c-2.6,0-4.7-2-4.7-4.6V7.4c0-2.5,2.1-4.6,4.7-4.6h0l-.1.1Z" fill="#fff"/>
        <path d="M12.8,20.9h35c3.4,0,6.5,1.4,8.6,3.7,2,2.1,3.2,4.9,3.2,7.9v5.9c0,5.8-4.3,10.5-10,11.4l6,12.2-16.2-12H12.8c-6.5,0-11.8-5.2-11.8-11.6v-5.9c0-6.4,5.3-11.6,11.8-11.6h0Z" fill="#74e0d4"/>
        <path d="M55.7,62.9c-.2,0-.4,0-.6-.2l-16-11.8H12.8c-7.1,0-12.8-5.6-12.8-12.5v-5.9c0-6.9,5.7-12.5,12.8-12.5h35c3.5,0,6.9,1.5,9.3,4,2.2,2.3,3.5,5.4,3.5,8.6v5.9c0,5.7-4,10.7-9.5,12.1l5.5,11.1c.2.4,0,.9-.3,1.2-.2.1-.4.2-.6.2h0v-.2ZM12.8,21.8c-6,0-10.8,4.8-10.8,10.6v5.9c0,5.9,4.8,10.6,10.8,10.6h26.6c.2,0,.4,0,.6.2l13,9.6-4.3-8.6c-.1-.3-.1-.6,0-.9.1-.3.4-.5.7-.5,5.2-.8,9.1-5.3,9.1-10.5v-5.9c0-2.7-1-5.3-3-7.2-2.1-2.1-4.9-3.4-7.9-3.4H12.8v.1Z" fill="#003057"/>
        <path d="M30.3,38.9c2,0,3.6-1.5,3.6-3.5s-1.6-3.5-3.6-3.5-3.6,1.5-3.6,3.5,1.6,3.5,3.6,3.5Z" fill="#fff"/>
        <path d="M30.3,39.8c-2.5,0-4.6-2-4.6-4.4s2.1-4.4,4.6-4.4,4.6,2,4.6,4.4-2.1,4.4-4.6,4.4ZM30.3,32.9c-1.4,0-2.6,1.1-2.6,2.5s1.2,2.5,2.6,2.5,2.6-1.1,2.6-2.5-1.2-2.5-2.6-2.5Z" fill="#003057"/>
        <path d="M43.2,38.9c2,0,3.6-1.5,3.6-3.5s-1.6-3.5-3.6-3.5-3.6,1.5-3.6,3.5,1.6,3.5,3.6,3.5Z" fill="#fff"/>
        <path d="M43.2,39.8c-2.5,0-4.6-2-4.6-4.4s2.1-4.4,4.6-4.4,4.6,2,4.6,4.4-2.1,4.4-4.6,4.4ZM43.2,32.9c-1.4,0-2.6,1.1-2.6,2.5s1.2,2.5,2.6,2.5,2.6-1.1,2.6-2.5-1.2-2.5-2.6-2.5Z" fill="#003057"/>
        <path d="M17.4,38.9c2,0,3.6-1.5,3.6-3.5s-1.6-3.5-3.6-3.5-3.6,1.5-3.6,3.5,1.6,3.5,3.6,3.5Z" fill="#fff"/>
        <path d="M17.4,39.8c-2.5,0-4.6-2-4.6-4.4s2.1-4.4,4.6-4.4,4.6,2,4.6,4.4-2.1,4.4-4.6,4.4ZM17.4,32.9c-1.4,0-2.6,1.1-2.6,2.5s1.2,2.5,2.6,2.5,2.6-1.1,2.6-2.5-1.2-2.5-2.6-2.5Z" fill="#003057"/>
      </g>
    </g>
  </svg>;

export default HowItWorksStep4