import React from 'react'

export const CreditCard = ({
                      style = {},
                      fill = '#fff',
                      width = '100%',
                      className = '',
                      height = '100%',
                      viewBox = '0 0 33 26',
                    }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="icon-credit-card_desktop">
      <path id="icon-credit-card" d="M28.5245 2.97917C29.0134 2.97917 29.4134 3.37917 29.4134 3.86806V5.64583H2.74674V3.86806C2.74674 3.37917 3.14674 2.97917 3.63563 2.97917H28.5245ZM29.4134 10.9792V21.6458C29.4134 22.1347 29.0134 22.5347 28.5245 22.5347H3.63563C3.14674 22.5347 2.74674 22.1347 2.74674 21.6458V10.9792H29.4134ZM3.63563 0.3125C1.67452 0.3125 0.0800781 1.90694 0.0800781 3.86806V21.6458C0.0800781 23.6069 1.67452 25.2014 3.63563 25.2014H28.5245C30.4856 25.2014 32.0801 23.6069 32.0801 21.6458V3.86806C32.0801 1.90694 30.4856 0.3125 28.5245 0.3125H3.63563ZM6.74675 17.2014C6.00786 17.2014 5.41341 17.7958 5.41341 18.5347C5.41341 19.2736 6.00786 19.8681 6.74675 19.8681H9.41341C10.1523 19.8681 10.7467 19.2736 10.7467 18.5347C10.7467 17.7958 10.1523 17.2014 9.41341 17.2014H6.74675ZM13.8579 17.2014C13.119 17.2014 12.5245 17.7958 12.5245 18.5347C12.5245 19.2736 13.119 19.8681 13.8579 19.8681H20.0801C20.819 19.8681 21.4134 19.2736 21.4134 18.5347C21.4134 17.7958 20.819 17.2014 20.0801 17.2014H13.8579Z" fill="#0032A0"/>
    </g>
  </svg>;

export default CreditCard