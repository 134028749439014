import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import {verifyUser} from '../actions/login-actions'
import WelcomeForm from '../components/welcome-form'
import {checkContactRecord} from "../actions/b-flow-actions";
import {logout} from "../actions/logout-actions";

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login,
    employer: state.employer,
    activeEmployers: state.activeEmployers,
    messagesByTo: state.messagesByTo,
    messagesByFrom: state.messagesByFrom,
    registration: state.registration
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout() {
      dispatch(logout())
    }
  }
}

function goToNextPage(props) {
  console.log("WelcomeForm Container -> goToNextPage")
  console.log(props.location)
  const string = props.location.search
  const search_for = 'sessions'
  if (string.includes(search_for)) {
    props.history.push(`login${string}`);
  } else {
    props.history.push(`/temporary-code`)
  }
}

const WelcomeFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
  form: 'WelcomeForm',
  validate: ValidationService.welcome,
  onSubmit: (values, dispatch, props) => {
     console.log("WelcomeFormContainer");
     console.log(props);
     console.log(values);
    return dispatch(verifyUser(props.values.username, "Client")).then(() => {
      dispatch(checkContactRecord(values)).then(() => {
        goToNextPage(props)
      })
    })
  },
  destroyOnUnmount: false
})(injectIntl(WelcomeForm))
)

export default WelcomeFormContainer

