import React from 'react'

export const WhoWeAreCeo = ({
                                  style = {},
                                  fill = '#000000',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 18.33 22',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M11.92,5.5c0-.73-.29-1.43-.81-1.94-.52-.52-1.22-.81-1.94-.81s-1.43.29-1.94.81c-.52.52-.81,1.22-.81,1.94s.29,1.43.81,1.94c.52.52,1.22.81,1.94.81s1.43-.29,1.94-.81c.52-.52.81-1.22.81-1.94ZM3.67,5.5c0-1.46.58-2.86,1.61-3.89,1.03-1.03,2.43-1.61,3.89-1.61s2.86.58,3.89,1.61c1.03,1.03,1.61,2.43,1.61,3.89s-.58,2.86-1.61,3.89c-1.03,1.03-2.43,1.61-3.89,1.61s-2.86-.58-3.89-1.61c-1.03-1.03-1.61-2.43-1.61-3.89ZM2.9,19.25h12.52c-.54-2.11-2.46-3.67-4.73-3.67h-3.06c-2.28,0-4.19,1.56-4.73,3.67ZM0,20.47c0-4.22,3.42-7.64,7.64-7.64h3.05c4.22,0,7.64,3.42,7.64,7.64,0,.84-.68,1.53-1.53,1.53H1.53c-.85,0-1.53-.68-1.53-1.53Z" fill="#2e2f2f"/>
    </g>
  </svg>;

export default WhoWeAreCeo