import {LINK_MONEY_FINANCIAL_SNAPSHOT} from "../actions/link-money-actions";

const linkMoneyFinancialSnapshot = (state = [], action) => {
    switch (action.type) {
        case LINK_MONEY_FINANCIAL_SNAPSHOT:
            return action.linkMoneyFinancialSnapshot;
        default:
            return state
    }
}

export default linkMoneyFinancialSnapshot