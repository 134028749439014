import React from 'react'

export const WhoWeAreFounded = ({
                                  style = {},
                                  fill = '#000000',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 21 24',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M7.12,1.13c0-.62-.5-1.13-1.12-1.13s-1.12.5-1.12,1.13v1.87h-1.88c-1.65,0-3,1.35-3,3v15c0,1.65,1.35,3,3,3h15c1.65,0,3-1.35,3-3V6c0-1.65-1.35-3-3-3h-1.88v-1.87c0-.62-.5-1.13-1.12-1.13s-1.12.5-1.12,1.13v1.87h-6.75v-1.87ZM2.25,9h16.5v12c0,.41-.34.75-.75.75H3c-.41,0-.75-.34-.75-.75v-12Z" fill="#2e2f2f"/>
    </g>
  </svg>;

export default WhoWeAreFounded