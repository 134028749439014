import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'

export default function RoundRobinDefault(props) {

  return (

            <div className="round-robin container-ap-full">

              <div className='working-america-container'>

                <div className='container-ap ptxxl'>
                  <div className='select-by-availability-intro'>

                      <div>
                        <h1 className='round-robin-title'>
                          <FormattedMessage id='app.round-robin.pick-a-time.title'/>
                        </h1>
                        <p>
                          <FormattedMessage id='app.round-robin.pick-a-time.instruction1'/>
                        </p>
                        <p>
                          <FormattedMessage id='app.round-robin.pick-a-time.instruction2'/>
                        </p>
                        <p>&nbsp;</p>
                        <p className="tpc-triangle-red">
                          <FormattedMessage id='app.round-robin.pick-a-time.select-by-availability'/>
                        </p>
                        <p>
                          <FormattedMessage id='app.round-robin.pick-a-time.select-date-instruction'/>
                        </p>
                      </div>


                  </div>

                  {props.displayCalendlyWidget()}

                </div>
              </div>
            </div>


  )
}
