import React from 'react'
import {useCalendlyEventListener, InlineWidget} from "react-calendly";

function stripPhone(phone) {
  let trimmedPhone = phone.trim();
  let one = trimmedPhone.substring(0, 1);
  let two = trimmedPhone.substring(0, 2);
  if (one === '1') {
    return trimmedPhone.slice(1).trim();
  } else if (two == "+1") {
    return trimmedPhone.slice(2).trim();
  } else {
    return trimmedPhone;
  }
}

function calendlyUrl(locale, counselor, registration, landingPage) {
  let url = baseCalendlyUrl(locale, counselor, landingPage)
  if (registration == null ) {
    return url;
  } else if (registration.mobilePhone == null) {
    let queryString = `?first_name=${registration.firstName}&last_name=${registration.lastName}`
    return `${url}${queryString}`
  } else {
    let queryString = `?first_name=${registration.firstName}&last_name=${registration.lastName}&a1=1${stripPhone(registration.mobilePhone)}`
    return `${url}${queryString}`
  }
}

function baseCalendlyUrl(locale, counselor, landingPage) {
  let storedUrl = locale === "en-US" ? counselor.englishCalendly : counselor.spanishCalendly
  return landingPage ? storedUrl.substring(0, storedUrl.lastIndexOf("/")) : storedUrl
}


export const CalendlyWidgetHelper = props => {

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => { props.onEventScheduled(e) },
      });


    let email = props.registration != null ? props.registration.email : ''
    console.log("CalendlyWidgetHelper -> " + email);

    return (
        <InlineWidget url={calendlyUrl(props.locale, props.counselor, props.registration, props.landingPage)}
            prefill={{email: email}}/>
        )
    }

export default CalendlyWidgetHelper