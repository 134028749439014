import React from 'react'

export const Coaching = ({
                        style = {},
                        fill = '#fff',
                        width = '100%',
                        className = '',
                        height = '100%',
                        viewBox = '0 0 101 97',
                      }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <rect width="100.649" height="97" rx="48.5" fill="#003057"/>
    <path d="M34.257 66.087c3.242 0 5.87-2.535 5.87-5.66 0-3.127-2.628-5.661-5.87-5.661-3.24 0-5.868 2.534-5.868 5.66 0 3.126 2.627 5.66 5.868 5.66zM21.967 79.227v-2.204c0-5.32 4.471-9.632 9.986-9.632h4.608c5.515 0 9.987 4.313 9.987 9.632v2.204c0 .893-.752 1.618-1.678 1.618H23.644c-.925 0-1.677-.725-1.677-1.618zM66.394 66.087c3.241 0 5.869-2.535 5.869-5.66 0-3.127-2.628-5.661-5.869-5.661-3.241 0-5.869 2.534-5.869 5.66 0 3.126 2.628 5.66 5.87 5.66zM54.103 79.227v-2.204c0-5.32 4.472-9.632 9.987-9.632h4.608c5.515 0 9.986 4.313 9.986 9.632v2.204c0 .893-.752 1.618-1.677 1.618H55.784c-.925 0-1.677-.725-1.677-1.618h-.004z" fill="#AEE0E8"/>
    <path d="M73.056 25.875v9.152c0 4.967-4.214 9.031-9.364 9.031h-.154l3.225 7.577c.074.17-.232.295-.405.167L55.88 44.058H44.22c-5.15 0-9.364-4.064-9.364-9.031v-9.152c0-4.967 4.214-9.031 9.364-9.031h19.471a9.5 9.5 0 0 1 6.816 2.858c1.578 1.618 2.548 3.79 2.548 6.173z" fill="#003057"/>
    <path d="M43.555 24.043H65.15M43.555 29.68H65.15m-21.595 5.64H65.15m7.906-9.445v9.152c0 4.967-4.214 9.031-9.364 9.031h-.154l3.225 7.577c.074.17-.232.295-.405.167L55.88 44.058H44.22c-5.15 0-9.364-4.064-9.364-9.031v-9.152c0-4.967 4.214-9.031 9.364-9.031h19.471a9.5 9.5 0 0 1 6.816 2.858c1.578 1.618 2.548 3.79 2.548 6.173z" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" fill="#003057"/>
    <path d="M28.469 34.441v3.751c0 4.036 3.42 7.335 7.605 7.335h.125l-2.62 6.155c-.06.139.187.238.327.135l8.508-6.286h9.47c4.185 0 7.606-3.3 7.606-7.335v-3.752c0-4.035-3.421-7.335-7.605-7.335H36.074a7.718 7.718 0 0 0-5.537 2.322c-1.28 1.316-2.068 3.08-2.068 5.013v-.003z" fill="#fff"/>
    <path d="M34.957 33.555h18.207m-18.207 5.218h18.207M28.47 34.441v3.751c0 4.036 3.42 7.335 7.605 7.335h.125l-2.62 6.155c-.06.139.187.238.327.135l8.508-6.286h9.47c4.185 0 7.606-3.3 7.606-7.335v-3.752c0-4.035-3.421-7.335-7.605-7.335H36.074a7.718 7.718 0 0 0-5.537 2.322c-1.28 1.316-2.068 3.08-2.068 5.013v-.003z" stroke="#003057" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" fill="white"/>
  </svg>;

export default Coaching