import axios from 'axios'
import {SubmissionError} from 'redux-form'

export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const SUBMIT_EMAIL = 'SUBMIT_EMAIL';
export const SUBMIT_TEMPCODE = 'SUBMIT_TEMPCODE';
export const SUBMIT_CREATE_PASS = 'SUBMIT_CREATE_PASS';
export const SUBMIT_CHANGE_PASS = 'SUBMIT_CHANGE_PASS';
export const SUBMIT_CHANGE_PHONE = 'SUBMIT_CHANGE_PHONE';
export const SUBMIT_CHANGE_EMAIL = 'SUBMIT_CHANGE_EMAIL';
export const SUBMIT_RESET_PASS = 'SUBMIT_RESET_PASS';
export const SUBMIT_RESEND_TEMPCODE = 'SUBMIT_RESEND_TEMPCODE';
export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const SUBMIT_EMAIL_TEMPCODE = 'SUBMIT_EMAIL_TEMPCODE';
export const CREATE_POSTGRES_RECORD = 'CREATE_POSTGRES_RECORD';
export const SET_LOGIN_PATH = 'SET_LOGIN_PATH';
export const SAML_RELAY = 'SAML_RELAY';



export const authenticate = (data, recordType) => {
    return (dispatch, getState) => {
        const authenticate = Object.assign({}, data)

        authenticate.username = getState().login.username;
        authenticate.recordType = recordType;

        return axios
            .post('/api/login', authenticate)
            .then((response) => {
              dispatch({
                  type: SUBMIT_LOGIN,
                  login: response.data
              })
            })
            .catch(error => {
              throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const verifyUser = (username, recordType) => {
    return (dispatch, getState) => {
        const login = new Object();

        login.username = username;
        login.recordType = recordType;

        return axios
            .post('/api/verifyUser', login)
            .then((response) => {
                dispatch({
                     type: SUBMIT_EMAIL,
                     login: response.data
                })
        })
        .catch(error => {
          dispatch({
            type: SUBMIT_EMAIL,
            login: null
          })
        })
    }
}

export const createPassword = data => {
    return (dispatch, getState) => {
        const createPassword = new Object();

        createPassword.username = getState().login.username;
        createPassword.newPassword = data.newpassword;
        createPassword.confirmPassword = data.confirmpassword;
        createPassword.contactId = getState().login.contactId;

        return axios
            .post('/api/createPassword', createPassword)
            .then((response) => {
            dispatch({
                         type: SUBMIT_CREATE_PASS,
                         login: response.data
                     })
        })
    .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
    }
}

export const resetPassword = data => {
    return (dispatch, getState) => {
        const resetPassword = Object.assign({}, data)

        resetPassword.username = getState().login.username;

        return axios
            .post('/api/resetPassword', resetPassword)
            .then((response) => {
            dispatch({
                         type: SUBMIT_RESET_PASS,
                         login: response.data
                     })
        })
    .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
    }
}

export const changePassword = data => {
    return (dispatch, getState) => {
        const changePassword = Object.assign({}, getState().login);

        changePassword.password = data.password;
        changePassword.newPassword = data.newpassword;
        changePassword.confirmPassword = data.confirmpassword;

        return axios
            .post('/api/changePassword', changePassword)
            .then((response) => {
                dispatch({
                    type: SUBMIT_CHANGE_PASS,
                    login: response.data
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}


export const changeEmail = data => {
    return (dispatch, getState) => {
        const changeEmail = getState().login;

        changeEmail.newUsername = data.newUsername;

        return axios
            .post('/api/changeEmail', changeEmail)
            .then((response) => {
                dispatch({
                    type: SUBMIT_CHANGE_EMAIL,
                    login: response.data
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const verifyToken = data => {
    return (dispatch, getState) => {
        const verifyToken = Object.assign({}, data)

        verifyToken.username = getState().login.username;

        return axios
            .post('/api/verifyToken', verifyToken)
            .then((response) => {
              let login = response.data === "" ? null : response.data
                dispatch({
                    type: VERIFY_TOKEN,
                    login: login
                })
            })
            .catch(error => {
              dispatch({
                type: VERIFY_TOKEN,
                login: null
              })
            })
    }
}

export const postgresPasswordReset = data => {
    return (dispatch, getState) => {
        const login = Object.assign({}, data)

        login.username = getState().login.username;

        return axios
            .post('/api/login/postgresPasswordReset', login)
            .then((response) => {
                console.log("password reset email has been sent")
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const createPostgresRecord = data => {
    return (dispatch, getState) => {
        const login = Object.assign({}, data)

        login.username = getState().registration.email;
        login.contactId = getState().registration.id;

        return axios
            .post('/api/login/createPostgresRecord', login)
            .then((response) => {
                dispatch({
                    type: CREATE_POSTGRES_RECORD,
                    login: response.data
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const samlRelay = data => {
    return (dispatch) => {
        const login = Object.assign({}, data)
        login.samlToken = data

        return axios
            .post('/api/saml-relay', login)
            .then((response) => {

              console.log(response.data)
                dispatch({
                    type: SAML_RELAY,
                    login: response.data.login,
                    registration: response.data.contact
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}
