import {FINANCIAL_SNAPSHOT_DOWNLOADED} from "../actions/link-money-actions";

const financialSnapshotDownloaded = (state = [], action) => {
    switch (action.type) {
        case FINANCIAL_SNAPSHOT_DOWNLOADED:
            return action.financialSnapshotDownloaded;
        default:
            return state
    }
}

export default financialSnapshotDownloaded