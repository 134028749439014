import axios from "axios";

export const LINK_MONEY_TOKEN = 'LINK_MONEY_TOKEN'
export const LINK_MONEY_CATEGORY_DATA = 'LINK_MONEY_CATEGORY_DATA'
export const LINK_MONEY_ACCOUNTS = 'LINK_MONEY_ACCOUNTS'
export const LINK_MONEY_TRANSACTIONS = 'LINK_MONEY_TRANSACTIONS'
export const DISPLAY_LINK_MONEY_MODAL = 'DISPLAY_LINK_MONEY_MODAL'
export const GET_CONNECTED_ACCOUNTS = 'GET_CONNECTED_ACCOUNTS'
export const LINK_MONEY_FINANCIAL_SNAPSHOT = 'LINK_MONEY_FINANCIAL_SNAPSHOT'
export const FINANCIAL_SNAPSHOT_DOWNLOADED = 'FINANCIAL_SNAPSHOT_DOWNLOADED'

export function getLinkMoneyToken(contactId) {
    return () => {
        return axios
            .get('/api/link-money/get-token', {params: {contactId}})
            .then(response => {
                console.log(response.data)
                return response.data
            })
            .catch(() => {})
    }
}

export function saveUserData(contactId, data) {
    return () => {
        const userData = Object.assign({})

        userData.events = [data];
        userData.contactId = contactId;

        console.log(userData)
        return axios
            .post('/api/link-money/get-accounts', userData)
            .then(response => {
                return response.data
            })
            .catch(() => {})
    }
}

export function getLinkedAccounts() {
  return (dispatch, getState) => {
    let contactId = getState().registration.id

    return axios
      .get('/api/link-money/get-linked-accounts', {params: {contactId}})
      .then(response => {
        dispatch({
          type: LINK_MONEY_ACCOUNTS,
          linkMoneyAccounts: response.data
        })
      })
      .catch(() => {})
  }
}

export function getCategories(data) {
  return (dispatch, getState) => {
    let accountId = data.accountId
    let startDate = data.startDate
    let endDate = data.endDate
    let categorySelected = data.categorySelected
    let contactId = getState().registration.id

    return axios
      .get('/api/link-money/get-categories', {params: {contactId, accountId, startDate, endDate, categorySelected}})
      .then(response => {
          console.log(response.data)
        dispatch({
          type: LINK_MONEY_CATEGORY_DATA,
          linkMoneyCategoryData: response.data
        })
      })
      .catch(() => {})
  }
}

export function getTransactions(data) {
    return (dispatch, getState) => {
        let accountId = data.accountId
        let contactId = getState().registration.id

        return axios
            .get('/api/link-money/get-transactions', {params: {contactId, accountId}})
            .then(response => {
                dispatch({
                    type: LINK_MONEY_TRANSACTIONS,
                    linkMoneyTransactions: response.data
                })
            })
            .catch(() => {})
    }
}

export function displayLinkMoneyModal(truthy) {
    let displayValue = truthy === true ? true : false
    return (dispatch) => {
        dispatch({
            type: DISPLAY_LINK_MONEY_MODAL,
            displayLinkMoneyModal: displayValue
        })
    }
}

export function getConnectedAccounts() {
    return (dispatch, getState) => {
        let contactId = getState().registration.id

        return axios
            .get('/api/link-money/get-connected-accounts', {params: {contactId}})
            .then(response => {
                dispatch({
                    type: GET_CONNECTED_ACCOUNTS,
                    linkMoneyAccounts: response.data
                })
            })
            .catch(() => {})
    }
}

export function generateLinkMoneyFinancialSnapshot() {
    return (dispatch, getState) => {
        let contactId = getState().registration.id

        return axios
            .get('/api/link-money/generate-snapshot-pdf', {params: {contactId}})
            .then(response => {
                dispatch({
                    type: LINK_MONEY_FINANCIAL_SNAPSHOT,
                    linkMoneyFinancialSnapshot: response.data
                })
            })
            .catch(() => {})
    }
}

export const fetchFinancialSnapshotFiles = () => {
    return (dispatch, getState) => {

        let contactId = getState().registration.id

        return axios
            .get('api/contact/get-financial-snapshot-files', {params: {contactId}})
            .then(response => {
                console.log(response.data)
                dispatch({
                    type: LINK_MONEY_FINANCIAL_SNAPSHOT,
                    linkMoneyFinancialSnapshot: response.data
                })
            })
            .catch(() => {
            })
    }
}

export function financialSnapshotDownloaded(truthy) {
    let displayValue = truthy === true ? true : false
    return (dispatch) => {
        dispatch({
            type: FINANCIAL_SNAPSHOT_DOWNLOADED,
            financialSnapshotDownloaded: displayValue
        })
    }
}