import React from 'react'

export const HowItWorksStep1 = ({
                            style = {},
                            fill = '#000000',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 124.55 90',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M108.73.91H15.82C7.59.91.91,7.59.91,15.82s6.67,14.91,14.91,14.91h92.91c8.23,0,14.91-6.67,14.91-14.91S116.96.91,108.73.91Z" fill="#fff"/>
      <path d="M108.73,31.64H15.82C7.1,31.64,0,24.55,0,15.83S7.1,0,15.82,0h92.91c8.72,0,15.82,7.1,15.82,15.83s-7.1,15.83-15.82,15.83v-.02ZM15.82,1.83C8.1,1.83,1.82,8.11,1.82,15.83s6.28,14,13.99,14h92.91c7.72,0,14-6.28,14-14s-6.28-14-14-14H15.82Z" fill="#003057"/>
      <path d="M62.27,19.68v15.06c2.43-2.12,7.08-2.39,10.09.82,3.25-1.86,7.55-.27,8.87,2.14,6.48-1.08,10.14,1.66,10.14,8.38v3.52c0,7.52-5.93,12.78-6.57,19.86h-29.23c-.49-4.18-3.5-7.1-6.55-9.77-2.1-1.86-4.18-3.6-6.59-5.09-3.87-2.39-9.27-4.33-9.27-5.79,0-3.87,1.46-7.28,5.82-7.28,5.82,0,8.78,4.36,11.64,4.36v-26.22c0-3.05,2.74-5.82,5.82-5.82s5.82,2.68,5.82,5.82Z" fill="#00c7b1"/>
      <path d="M85.65,70.36h-30.87l-.09-.8c-.46-3.83-3.21-6.54-6.24-9.2-1.79-1.61-3.96-3.45-6.48-5-1.33-.82-2.86-1.59-4.32-2.34-3.25-1.64-5.38-2.72-5.38-4.22,0-5.2,2.46-8.2,6.73-8.2,3.69,0,6.26,1.61,8.34,2.88.88.55,1.68,1.04,2.39,1.3v-25.12c0-3.6,3.14-6.74,6.73-6.74s6.73,3.09,6.73,6.74v13.38c2.74-1.33,6.59-1.13,9.36,1.39,1.59-.71,3.41-.82,5.24-.31,1.64.46,3.07,1.41,3.9,2.56,3.32-.46,5.93.11,7.75,1.66,1.9,1.61,2.85,4.2,2.85,7.7v3.52c0,4.22-1.75,7.72-3.45,11.1-1.46,2.92-2.85,5.7-3.12,8.84l-.07.84v.02ZM56.36,68.53h27.64c.46-3.14,1.86-5.95,3.21-8.65,1.68-3.34,3.25-6.5,3.25-10.28v-3.52c0-2.96-.75-5.08-2.21-6.32-1.51-1.28-3.81-1.66-6.88-1.15l-.64.11-.31-.57c-.53-.99-1.75-1.83-3.16-2.23-1.59-.44-3.17-.29-4.45.46l-.62.37-.49-.53c-2.63-2.79-6.68-2.65-8.83-.77l-1.51,1.33v-17.07c0-2.61-2.3-4.91-4.91-4.91s-4.91,2.34-4.91,4.91v27.13h-.91c-1.44,0-2.76-.82-4.27-1.75-1.97-1.22-4.21-2.61-7.37-2.61-4.05,0-4.91,3.47-4.91,6.37.22.49,2.63,1.7,4.38,2.59,1.44.73,3.07,1.53,4.47,2.41,2.63,1.64,4.87,3.54,6.71,5.18,2.97,2.61,5.93,5.49,6.71,9.55v-.05Z" fill="#003057"/>
      <path d="M85.94,69.52h-32.13c-2.8,0-5.07,2.27-5.07,5.08v9.42c0,2.8,2.27,5.07,5.07,5.07h32.13c2.8,0,5.07-2.27,5.07-5.07v-9.42c0-2.8-2.27-5.08-5.07-5.08Z" fill="#fff"/>
      <path d="M85.94,90h-32.13c-3.3,0-5.98-2.68-5.98-5.99v-9.44c0-3.3,2.68-5.99,5.98-5.99h32.13c3.3,0,5.98,2.68,5.98,5.99v9.44c0,3.3-2.68,5.99-5.98,5.99ZM53.8,70.43c-2.3,0-4.16,1.86-4.16,4.16v9.44c0,2.3,1.86,4.16,4.16,4.16h32.13c2.3,0,4.16-1.86,4.16-4.16v-9.44c0-2.3-1.86-4.16-4.16-4.16h-32.13Z" fill="#003057"/>
      <path d="M84.15,82.65c1.84,0,3.34-1.5,3.34-3.34s-1.49-3.34-3.34-3.34-3.34,1.5-3.34,3.34,1.49,3.34,3.34,3.34Z" fill="#fff"/>
      <path d="M84.15,83.56c-2.35,0-4.25-1.9-4.25-4.25s1.9-4.25,4.25-4.25,4.25,1.9,4.25,4.25-1.9,4.25-4.25,4.25ZM84.15,76.88c-1.33,0-2.43,1.1-2.43,2.43s1.09,2.43,2.43,2.43,2.43-1.1,2.43-2.43-1.09-2.43-2.43-2.43Z" fill="#003057"/>
    </g>
  </svg>;

export default HowItWorksStep1