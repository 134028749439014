import React from 'react'

export const HowItWorksStep3 = ({
                                  style = {},
                                  fill = '#000000',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 118 86.99',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M5.31.95C2.91.95.95,2.93.95,5.34v16.09L20.27.95H5.31Z" fill="#00c7b1"/>
      <path d="M0,23.83V5.34C0,2.4,2.38,0,5.31,0h17.15L0,23.83ZM5.31,1.9c-1.89,0-3.42,1.54-3.42,3.44v13.71L18.07,1.9H5.31Z" fill="#003057"/>
      <path d="M112.69.95H20.27L.95,21.45v60.19c0,2.43,1.97,4.39,4.37,4.39h107.38c2.42,0,4.37-1.98,4.37-4.39V5.34c0-2.43-1.97-4.39-4.37-4.39Z" fill="#fff"/>
      <path d="M112.69,86.99H5.31c-2.93,0-5.31-2.4-5.31-5.34V21.07L19.87,0h92.82c2.93,0,5.31,2.4,5.31,5.34v76.3c0,2.95-2.38,5.34-5.31,5.34ZM1.89,21.83v59.83c0,1.9,1.53,3.44,3.42,3.44h107.36c1.89,0,3.42-1.54,3.42-3.44V5.34c0-1.9-1.53-3.44-3.42-3.44H20.68L1.89,21.83Z" fill="#003057"/>
      <path d="M49.36,35.74h-20.42c-.53,0-.95-.42-.95-.95s.42-.95.95-.95h20.42c.53,0,.95.42.95.95s-.42.95-.95.95Z" fill="#00c7b1"/>
      <path d="M49.36,64.25h-20.42c-.53,0-.95-.42-.95-.95s.42-.95.95-.95h20.42c.53,0,.95.42.95.95s-.42.95-.95.95Z" fill="#00c7b1"/>
      <path d="M77.11,42.55h-6.62c-2.42,0-4.39-1.98-4.39-4.41v-6.66c0-2.43,1.97-4.41,4.39-4.41h6.62c2.42,0,4.39,1.98,4.39,4.41v6.66c0,2.43-1.97,4.41-4.39,4.41ZM70.5,28.99c-1.38,0-2.5,1.12-2.5,2.51v6.66c0,1.39,1.12,2.51,2.5,2.51h6.62c1.38,0,2.5-1.12,2.5-2.51v-6.66c0-1.39-1.12-2.51-2.5-2.51h-6.62Z" fill="#003057"/>
      <path d="M107.68,35.74h-20.42c-.53,0-.95-.42-.95-.95s.42-.95.95-.95h20.42c.53,0,.95.42.95.95s-.42.95-.95.95Z" fill="#00c7b1"/>
      <path d="M77.11,68.7h-6.62c-2.42,0-4.39-1.98-4.39-4.41v-6.66c0-2.43,1.97-4.41,4.39-4.41h6.62c2.42,0,4.39,1.98,4.39,4.41v6.66c0,2.43-1.97,4.41-4.39,4.41ZM70.5,55.12c-1.38,0-2.5,1.12-2.5,2.51v6.66c0,1.39,1.12,2.51,2.5,2.51h6.62c1.38,0,2.5-1.12,2.5-2.51v-6.66c0-1.39-1.12-2.51-2.5-2.51h-6.62Z" fill="#003057"/>
      <path d="M107.68,64.25h-20.42c-.53,0-.95-.42-.95-.95s.42-.95.95-.95h20.42c.53,0,.95.42.95.95s-.42.95-.95.95Z" fill="#00c7b1"/>
      <path d="M18.77,54.17h-6.6c-1.9,0-3.44,1.55-3.44,3.46v6.64c0,1.91,1.54,3.46,3.44,3.46h6.6c1.9,0,3.44-1.55,3.44-3.46v-6.64c0-1.91-1.54-3.46-3.44-3.46Z" fill="#ddf2f7"/>
      <path d="M29.11,49.6c-.4-.34-1-.29-1.32.13l-5.01,6.09c-.7-1.52-2.21-2.59-3.99-2.59h-6.62c-2.42,0-4.39,1.98-4.39,4.41v6.66c0,2.43,1.97,4.41,4.39,4.41h6.62c2.42,0,4.39-1.98,4.39-4.41v-5.99l6.07-7.38c.34-.4.28-1.01-.13-1.33ZM21.27,64.28c0,1.39-1.12,2.51-2.5,2.51h-6.62c-1.38,0-2.5-1.12-2.5-2.51v-6.66c0-1.39,1.12-2.51,2.5-2.51h6.62c1.36,0,2.48,1.12,2.5,2.49l-3.53,4.3-4.16-3.8c-.38-.34-.98-.32-1.34.06-.36.38-.32.99.06,1.35l4.9,4.47c.17.15.4.25.64.25h.08c.26,0,.49-.15.66-.34l2.7-3.29v3.69Z" fill="#003057"/>
      <path d="M18.77,28.02h-6.6c-1.9,0-3.44,1.55-3.44,3.46v6.64c0,1.91,1.54,3.46,3.44,3.46h6.6c1.9,0,3.44-1.55,3.44-3.46v-6.64c0-1.91-1.54-3.46-3.44-3.46Z" fill="#ddf2f7"/>
      <path d="M29.11,23.43c-.4-.34-1-.29-1.32.13l-5.01,6.09c-.7-1.52-2.21-2.59-3.99-2.59h-6.62c-2.42,0-4.39,1.98-4.39,4.41v6.66c0,2.43,1.97,4.41,4.39,4.41h6.62c2.42,0,4.39-1.98,4.39-4.41v-5.99l6.07-7.38c.34-.4.28-1.01-.13-1.33ZM21.27,38.13c0,1.39-1.12,2.51-2.5,2.51h-6.62c-1.38,0-2.5-1.12-2.5-2.51v-6.66c0-1.39,1.12-2.51,2.5-2.51h6.62c1.36,0,2.48,1.12,2.5,2.49l-3.53,4.3-4.16-3.8c-.38-.34-.98-.32-1.34.06-.36.38-.32.99.06,1.35l4.9,4.47c.17.15.4.25.64.25h.08c.26,0,.49-.15.66-.34l2.7-3.29v3.69Z" fill="#003057"/>
    </g>
  </svg>;

export default HowItWorksStep3