import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icon from "../../../resources/icons";

export default function HomePageChecklistWa(props) {

  return (
    <div className='intro-text-landing-page-wrapper working-america'>
      <div className='landing-container intro-text'>
        <h1>
          <FormattedMessage id='app.landing-page.introduction.working-america-title'/>
        </h1>
        <h2>
          <FormattedMessage id='app.landing-page.introduction.working-america-subtitle'/>
        </h2>
        <div className='checklist-intro-text'>
          <FormattedMessage id='app.landing-page.introduction.working-america-checklist-intro-text'/>
        </div>
          <div className='working-america-checklist-container'>
            <div className='working-america-checklist-wrapper'>
              <div className='working-america-checklist-icon'>
                <Icon name='credit-card'></Icon>
              </div>
              <div className='working-america-checklist-text'>
                <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-1'/>

              </div>
            </div>
            <div className='working-america-checklist-wrapper'>
              <div className='working-america-checklist-icon'>
                <Icon name='handshake'></Icon>
              </div>
              <div className='working-america-checklist-text'>
                <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-2'/>
              </div>
            </div>
            <div className='working-america-checklist-wrapper'>
              <div className='working-america-checklist-icon'>
                <Icon name='graduation-cap'></Icon>
              </div>
              <div className='working-america-checklist-text'>
                <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-3'/>
              </div>
            </div>
            <div className='working-america-checklist-wrapper'>
              <div className='working-america-checklist-icon'>
                <Icon name='money'></Icon>
              </div>
              <div className='working-america-checklist-text'>
                <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-4'/>
              </div>
            </div>
          </div>
        <div className='checklist-end'>
        <p className='checklist-end-p'>
          <FormattedMessage id='app.landing-page.introduction.working-america.heading-text-1'/>
        </p>
        <p>
          <FormattedMessage id='app.landing-page.introduction.working-america.heading-text-2'/>
        </p>
      </div>

        <div className='submit-button-round landing-submit-button-desktop'>
          <button className='btn btn-primary btn-block btn-intro'
                  id='submit-next-location'
                  onClick={()=>props.determineNextLocation()}>
            {props.employerDefaultsToAskACoach() ?
              <FormattedMessage id='app.landing-page.introduction.working-america.button-1'/>
              :
              <FormattedMessage id='app.landing-page.introduction.working-america.button-1'/>
            }
          </button>
        </div>
        <div className='submit-button-round landing-submit-button-mobile'>
          <button className='btn btn-primary btn-block btn-intro'
                  id='mobile-submit-next-location'
                  onClick={()=>props.determineNextLocation()}>
            {props.employerDefaultsToAskACoach() ?
              <FormattedMessage id='app.landing-page.introduction.working-america.button-1'/>
              :
              <FormattedMessage id='app.landing-page.introduction.working-america.button-1'/>
            }
          </button>
        </div>
      </div>
    </div>
  )
}
