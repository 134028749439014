import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import Icon from '../../resources/icons/'
import { withRouter } from 'react-router-dom';

class Header_AP extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false
        }
    }

    onLocalToggle(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.onLocaleToggle(this.props.locale);
    }
    goToWelcomePage(e) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
      this.props.history.push(`/welcome`)
    }

    render() {

        return (
            <div className='header'>
                <div className='page-header-ap'>
                    <div className='wa-container-ap header-ap-flex'>
                      <div className='logo-wrapper'>
                        <div className='logo'>
                          <Icon name="tp-full-logo"/>
                        </div>
                      </div>

                      <div className='header-ap-links'>
                        <button className='spanish-link'
                                onClick={ e => this.onLocalToggle(e) }>
                          <FormattedMessage id='app.introduction.locale-link'/>
                        </button>
                        <button className='spanish-link login-link'
                                onClick={e => this.goToWelcomePage(e)}>
                          <FormattedMessage id='app.landing-page.introduction.log-on.button'/>
                        </button>
                      </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Header_AP)