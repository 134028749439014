import React from 'react'

export const WhoWeAreCompany = ({
                                  style = {},
                                  fill = '#000000',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 18 24',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M3,2.25c-.41,0-.75.34-.75.75v18c0,.41.34.75.75.75h3.75v-3c0-1.24,1.01-2.25,2.25-2.25s2.25,1.01,2.25,2.25v3h3.75c.41,0,.75-.34.75-.75V3c0-.41-.34-.75-.75-.75H3ZM0,3C0,1.35,1.35,0,3,0h12c1.65,0,3,1.35,3,3v18c0,1.65-1.35,3-3,3H3c-1.65,0-3-1.35-3-3V3ZM4.13,4.88c0-.41.34-.75.75-.75h2.25c.41,0,.75.34.75.75v2.25c0,.41-.34.75-.75.75h-2.25c-.41,0-.75-.34-.75-.75v-2.25ZM10.87,4.13h2.25c.41,0,.75.34.75.75v2.25c0,.41-.34.75-.75.75h-2.25c-.41,0-.75-.34-.75-.75v-2.25c0-.41.34-.75.75-.75ZM4.13,10.87c0-.41.34-.75.75-.75h2.25c.41,0,.75.34.75.75v2.25c0,.41-.34.75-.75.75h-2.25c-.41,0-.75-.34-.75-.75v-2.25ZM10.87,10.12h2.25c.41,0,.75.34.75.75v2.25c0,.41-.34.75-.75.75h-2.25c-.41,0-.75-.34-.75-.75v-2.25c0-.41.34-.75.75-.75Z" fill="#2e2f2f"/>
    </g>
  </svg>;

export default WhoWeAreCompany