import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Icon from "../../../resources/icons";

export default function RoundRobinWa(props) {

  const [num, setNum] = useState(null);

  useEffect(() => {
    setNum(Math.floor(Math.random()*4) + 1)
  });

  return (
            <div>
              <div className='working-america-hero-container '>
                <div className='working-america-hero-wrapper '>
                  <div className='working-america-text-wrapper'>
                    <h1>
                      <FormattedMessage id='app.round-robin.pick-a-time.working-america.title'/>
                    </h1>
                    <h2>
                      <FormattedMessage id='app.round-robin.pick-a-time.working-america.subtitle'/>
                    </h2>
                    <div className='working-america-checklist-container'>
                      <div className='working-america-checklist-wrapper'>
                        <div className='working-america-checklist-icon'>
                          <Icon name='credit-card'></Icon>
                        </div>
                        <div className='working-america-checklist-text'>
                          <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-1'/>

                        </div>
                      </div>
                      <div className='working-america-checklist-wrapper'>
                        <div className='working-america-checklist-icon'>
                          <Icon name='handshake'></Icon>
                        </div>
                        <div className='working-america-checklist-text'>
                          <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-2'/>
                        </div>
                      </div>
                      <div className='working-america-checklist-wrapper'>
                        <div className='working-america-checklist-icon'>
                          <Icon name='graduation-cap'></Icon>
                        </div>
                        <div className='working-america-checklist-text'>
                          <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-3'/>
                        </div>
                      </div>
                      <div className='working-america-checklist-wrapper'>
                        <div className='working-america-checklist-icon'>
                          <Icon name='money'></Icon>
                        </div>
                        <div className='working-america-checklist-text'>
                          <FormattedMessage id='app.round-robin.pick-a-time.working-america.bullet-4'/>
                        </div>
                      </div>
                    </div>
                    <p className='checklist-end'>
                      <FormattedMessage id='app.round-robin.pick-a-time.working-america.heading-text-1'/>
                    </p>
                    <p>
                      <span className='bold'> <FormattedMessage id='app.round-robin.pick-a-time.working-america.heading-text-2'/> </span>
                      <FormattedMessage id='app.round-robin.pick-a-time.working-america.heading-text-3'/>
                    </p>
                  </div>
                  <div className='working-america-hero-image-container'>
                    <div className={`working-america-hero-image-${num} working-america-hero-image`}>
                    </div>
                    <div className='working-america-hero-image-quote'>
                      <div className='quote'>
                        <FormattedMessage id={`app.dcl-landing-page.testimonials.testimonial-${num}`}/>
                      </div>
                      <div className='author'>
                        <FormattedMessage id={`app.dcl-landing-page.testimonials.testimonial-author-${num}`}/>, TrustPlus client
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="round-robin scheduling-working-america working-america-container-ap">

              <div className='working-america-container'>

                <div className='calendly-container'>
                  <div className='calendly-intro-box'>
                    <div className='calendly-intro-line'>
                      <div className='calendly-intro-icon'>
                        <Icon name='coaching'></Icon>
                      </div>
                      <h2>
                        <FormattedMessage id='app.round-robin.pick-a-time.working-america.box1-text-1'/>
                      </h2>
                    </div>
                    <hr />
                    <div>
                      <p>
                        <FormattedMessage id='app.round-robin.pick-a-time.working-america.box1-text-2'/>
                      </p>
                    </div>

                </div>

                  {props.displayCalendlyWidget()}

                </div>

                <div className='working-america-col-2-wrapper'>
                  <div className='working-america-video-container'>
                    <div className='working-america-video'>
                      <div className='coach-landing-video working-america-video'>
                        <iframe width="auto" height="335"
                                src="https://www.youtube.com/embed/K6-Vg9fGqVA?si=equgtgTF35T_3c_8"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className='working-america-video-text-wrapper'>
                      <div className='icon'>
                        <Icon name='video'></Icon>
                      </div>
                      <h2>
                        <FormattedMessage id='app.round-robin.pick-a-time.working-america.video-heading'/>
                      </h2>
                      <div></div>
                      <div className='wa-text'>
                        <div className='working-america-video-text'>
                          <FormattedMessage id='app.round-robin.pick-a-time.working-america.video-text'/>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className='working-america'>
                    <div className='wa-intro-text-wrapper'>
                      <div className='shield-icon'>
                        <Icon name='shield-icon'/>
                      </div>
                      <div className='working-america-text'>
                        <FormattedMessage id='app.round-robin.pick-a-time.working-america.privacy-text'/>
                      </div>
                    </div>
                    <hr />
                    <div className='wa-logos'>
                      <div className='working-america-tp-logo'>
                        <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/TrustPlus_logo.png`} />
                      </div>
                      <div className='working-america-logo'>
                        <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/Customer_Logos/Ij08.png`} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
  )
}
