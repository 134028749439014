import React from 'react'

export const X = ({
                           style = {},
                           fill = '#fff',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 512 512',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="headerandfooter_w360-(displaywidths:-320-649)" transform="translate(-45.000000, -4042.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="x" transform="translate(45.000000, 4042.000000)">
          <path d="M389.2,48h70.6l-154.2,176.2,181.4,239.8h-142l-111.3-145.4-127.2,145.4H35.8l164.9-188.5L26.8,48h145.6l100.5,132.9,116.3-132.9ZM364.4,421.8h39.1L151.1,88h-42l255.3,333.8Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>;

export default X