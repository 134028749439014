import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";

function IntroductionTagline(props) {

  return(
      <div className='tagline-landing-page'>
        <div className='landing-container'>
          <h2><FormattedMessage id='app.landing-page.tagline.title'/></h2>
          <div className='tagline-text'>
            <FormattedMessage id='app.landing-page.tagline.text'/>
          </div>

          <div className='submit-button-round speak-with-coach-btn '>
            <button className='btn btn-primary btn-block btn-intro lg-height'
                    id='submit-speak-with-a-coach'
                    onClick={()=>props.determineNextLocation()}>
              {props.employerDefaultsToAskACoach() ?
                <FormattedMessage id='app.landing-page.tagline.get-started.button'/>
                :
                <FormattedMessage id='app.landing-page.introduction.sign-up.button'/>
              }
            </button>
          </div>
        </div>
      </div>
    )
}
export default IntroductionTagline