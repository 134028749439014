import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import FormattedMessage from "../helpers/FormattedMessage";
import axios from "axios";
import {useHistory} from "react-router-dom";

export default function ActionPlanLinkMoneyGateway () {

    const login = useSelector(state => state.login)

    const [token, setToken] = useState(null)
    const [onLoadCounter, setOnLoadCounter] = useState(0)
    const [devUrl, setDevUrl] = useState('');

    const redirectUrl = 'https://' + window.location.host + '/action-plan';

    const history = useHistory();

    useEffect(() => {
        if(token == null){
            getLinkMoneyToken(login.contactId)
        }
    }, [token])

    useEffect(() => {
        if (onLoadCounter > 1){
            history.push('/action-plan/link-money/confirmation')
        }
    })

    useEffect(() => {
        if (window.location.origin != 'https://go.mytrustplus.org') {
            console.log("development")
            setDevUrl('-dev');
        }
    }, []);

    function getLinkMoneyToken(contactId) {
        axios.get('/api/link-money/get-token', {params: {contactId}})
            .then(response => {
                setToken(response.data)
            })
            .catch(() => {})
    }

    function openLinkMoneyGateway() {
        if(token != null){
            return (
                <iframe src={`https://linkmoney-gateway${devUrl}.fingoal.com/api/authenticate?token=${token}&redirectUri=${redirectUrl}`} className="link-money-gateway" onLoad={(event) => redirectToConfirmation(event)} referrerPolicy="origin"></iframe>
            )
        }
    }

    function redirectToConfirmation() {
        setOnLoadCounter(onLoadCounter + 1)
    }

    return (
        <div id='action-plan-link-money-token'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                        <FormattedMessage id='app.action-plan-link-money.title'/>
                    </h1>
                </div>

                <h1 className="link-money-gateway-instructions">
                    <FormattedMessage id='app.action-plan-link-money.instructions'/>
                </h1>

                {openLinkMoneyGateway()}

                <FooterActionPlan />
            </div>
        </div>
    )
}