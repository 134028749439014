import React, {useEffect, useState} from 'react';
import FormattedMessage from '../helpers/FormattedMessage'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {displayLinkMoneyModal, getLinkedAccounts} from "../../actions/link-money-actions";

export default function ActionPlanLinkMoneyModal(props) {

    const [modal, setModal] = useState(true)
    const [pdfConsent, setPdfConsent] = useState(true);

    const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)
    const linkMoneyFinancialSnapshot = useSelector(state => state.linkMoneyFinancialSnapshot)
    const financialSnapshotDownloaded = useSelector(state => state.financialSnapshotDownloaded)

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(linkMoneyAccounts && linkMoneyAccounts.length > 0){
            if (linkMoneyFinancialSnapshot.length > 0 && financialSnapshotDownloaded == false && pdfConsent == true) {
                dispatch(displayLinkMoneyModal(true))
            } else {
                dispatch(displayLinkMoneyModal(false))
                setModal(false)
            }
        } else if (modal == false) {
            dispatch(displayLinkMoneyModal(false))
        } else {
            dispatch(displayLinkMoneyModal(true))
        }
    }, [])

    useEffect(() => {
        linkMoneyAccounts && linkMoneyAccounts.map(account => {
            if (new Date(account.createdAt) < new Date(2024, 10, 17)) {
                setPdfConsent(false)
            }
        })
    })

    function linkAccounts() {
        dispatch(displayLinkMoneyModal(false))
        history.push('/action-plan/link-money/get-started')
    }

    function closeModal() {
        setModal(false)
        dispatch(displayLinkMoneyModal(false))
    }

    return (
        <div className="link-money-modal">
            {
                linkMoneyFinancialSnapshot.length > 0 && financialSnapshotDownloaded == false ?

                    <div className="link-money-financial-snapshot-modal-body" style={{
                        transform: modal ? 'none' : 'translateY(-100vh)',
                        opacity: modal ? '1' : '0',
                        display: modal ? 'block' : 'none'
                    }}>
                        <div className='link-money-modal-pointer'></div>

                        <div className='link-money-modal-title margin-bottom-24-px'>
                            <FormattedMessage id='app.action-plan-link-money-modal.financial-snapshot.title'/>
                        </div>

                        <div className='link-money-modal-text margin-bottom-24-px'>
                            <FormattedMessage id='app.action-plan-link-money-modal.financial-snapshot.description-1'/>
                            <a onClick={() => linkAccounts()}>
                                <FormattedMessage id='app.action-plan-link-money-modal.financial-snapshot.link'/>
                            </a>
                            <FormattedMessage id='app.action-plan-link-money-modal.financial-snapshot.description-2'/>
                        </div>

                        <div className='link-money-modal-cancel'>
                            <a onClick={(e) => closeModal()}>
                                <FormattedMessage id='app.action-plan-link-money.button.cancel'/>
                            </a>
                        </div>

                    </div>

                :
                <div className="link-money-modal-body" style={{
                transform: modal ? 'none' : 'translateY(-100vh)',
                opacity: modal ? '1' : '0',
                display: modal ? 'block' : 'none'
                }}>
                <div className='link-money-modal-pointer'></div>

                <div className='link-money-modal-title margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-link-money-modal.title'/>
                </div>

                <div className='link-money-modal-text margin-bottom-24-px'>
                    <a onClick={() => linkAccounts()}>
                        <FormattedMessage id='app.action-plan-link-money-modal.link-1'/>
                    </a>
                    <FormattedMessage id='app.action-plan-link-money-modal.description-1'/>
                    <span className='link-money-modal-bold'>
                            <FormattedMessage id='app.action-plan-link-money-modal.description-2'/>
                        </span>
                    <FormattedMessage id='app.action-plan-link-money-modal.description-3'/>
                    <a onClick={() => linkAccounts()}>
                        <FormattedMessage id='app.action-plan-link-money-modal.link-2'/>
                    </a>.
                </div>

                <div className='link-money-modal-cancel'>
                    <a onClick={(e) => closeModal()}>
                        <FormattedMessage id='app.action-plan-link-money.button.cancel'/>
                    </a>
                </div>
            </div>

        }
        </div>
    )


}


