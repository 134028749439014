import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'

export default function FormError({error, employerCode}) {

  if (!error) return null

  if (error == 'app.errors.contact.create') {
    return (
    <div className="validation-error alert alert-warning bold small-text">
      <FormattedMessage id={error}
        values={{
          // alternativeSchedulerLink: (
          //   <a target='_blank' href='http://bit.ly/2krEB4t' id='external-scheduler'>
          //     <FormattedMessage id='app.errors.contact.create.link.external.self-scheduler.display'/>
          //   </a>
          // ),
          tollfreePhone: ( <FormattedMessage id='app.common.phone.tollfree'/>),
          supportEmail: (
            <a href='mailto:support@mytrustplus.org'>
              <FormattedMessage id='app.common.email.support'/>
            </a>
          )
        }}
      />
    </div>
    )
  } else if (error == 'app.errors.tpc.contact-already-exists'){
      return (
          <div className="validation-error alert alert-warning bold small-text">
              <FormattedMessage id={error}
                                values={{
                                    loginLink: (
                                      <a target='_blank' href={`${employerCode}/trustplus-credit/login`} id='login-link'>
                                        <FormattedMessage id='app.errors.tpc.contact-already-exists.login' />
                                      </a>
                                    ),
                                    tollfreePhone: ( <FormattedMessage id='app.common.phone.tollfree'/>),
                                    supportEmail: (
                                        <a href='mailto:support@mytrustplus.org'>
                                            <FormattedMessage id='app.common.email.support'/>
                                        </a>
                                    )
                                }}
              />
          </div>
      )
  } else if (error == 'app.errors.contact-exists'){
      return (
          <div className="validation-error alert alert-warning bold small-text">
              <FormattedMessage id={error}
                                values={{
                                    loginLink: (
                                      <a target='_blank' href='/welcome' id='login-link'>
                                        <FormattedMessage id='app.errors.contact-exists.login' />
                                      </a>
                                    ),
                                    tollfreePhone: ( <FormattedMessage id='app.common.phone.tollfree'/>),
                                    supportEmail: (
                                        <a href='mailto:support@mytrustplus.org'>
                                            <FormattedMessage id='app.common.email.support'/>
                                        </a>
                                    )
                                }}
              />
          </div>
      )
  }
  else {
      return (
          <div className="validation-error alert alert-warning bold small-text">
              <FormattedMessage id={error}/>
          </div>
      )
  }
}
