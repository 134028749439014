import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import FormattedMessage from "../helpers/FormattedMessage";
import {useHistory} from "react-router-dom";
import {displayLinkMoneyModal, getLinkedAccounts} from "../../actions/link-money-actions";
import Icon from "../../../resources/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ActionPlanLinkMoneyLanding (props) {

    const login = useSelector(state => state.login)
    const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(displayLinkMoneyModal(false))

        if(linkMoneyAccounts.length > 0) {
            history.push('/action-plan/link-money/accounts')
        }
    })

    return (
        <div id='action-plan-link-money-landing'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                        <FormattedMessage id='app.action-plan-link-money.title'/>
                    </h1>
                </div>

                <div className='link-money-landing-header margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-link-money.welcome'/>
                </div>

                <div className='link-money-landing-description margin-bottom-24-px'>
                    <div className='link-money-landing-description margin-bottom-8-px'>
                        <FormattedMessage id='app.action-plan-link-money.welcome.description-1'/>
                    </div>
                    <a onClick={() => history.push('/action-plan/link-money/gateway')}>
                        <FormattedMessage id='app.action-plan-link-money.welcome.description-link'/>
                    </a>
                    <FormattedMessage id='app.action-plan-link-money.welcome.description-2'/>

                </div>

                <div className='link-money-landing-description margin-bottom-24-px'>

                    <div className='link-money-landing-title margin-bottom-24-px'>
                        <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work.title'/>
                    </div>

                    <div className='link-money-landing-copy-container margin-bottom-8-px'>

                        <div className='link-money-landing-icons-container'>
                            <div className='link-money-landing-icons'>
                                <Icon name="shield"/>
                            </div>
                        </div>

                        <div className='link-money-landing-how-does-it-work-copy margin-bottom-8-px'>
                            <div className='margin-bottom-8-px'>
                                <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-1'/>
                                <span className='link-money-landing-text-style-1 '>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-1a'/>
                                </span>
                                <span>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-1b'/>
                                </span>
                            </div>
                            <div className='margin-bottom-8-px'>
                                <span>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-2'/>
                                </span>
                                <span className='link-money-landing-text-style-1 '>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-2a'/>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='link-money-landing-copy-container margin-bottom-8-px'>

                        <div className='link-money-landing-icons-container'>
                            <div className='link-money-landing-icons'>
                                <Icon name="scan"/>
                            </div>
                        </div>

                        <div className='link-money-landing-how-does-it-work-copy margin-bottom-8-px'>
                            <div className='margin-bottom-8-px'>
                                <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-3'/>
                                <span className='link-money-landing-text-style-1 '>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-3a'/>
                                </span>
                                <span>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-3b'/>
                                </span>
                                <span className='link-money-landing-text-style-1 '>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-3c'/>
                                </span>
                                 <span>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-3d'/>
                                </span>
                            </div>

                            <span>
                                <FormattedMessage id='app.action-plan-link-money.welcome.how-does-it-work-4'/>
                            </span>
                        </div>
                    </div>

                    <div className='link-money-landing-title margin-bottom-24-px'>
                        <FormattedMessage id='app.action-plan-link-money.welcome.ready-link-accounts-title'/>
                    </div>

                    <div className='link-money-landing-copy-container margin-bottom-8-px'>

                        <div className='link-money-landing-icons-container'>
                            <div className='link-money-landing-icons'>
                                <Icon name="bank"/>
                            </div>
                        </div>

                        <div className='link-money-landing-how-does-it-work-copy margin-bottom-8-px'>
                            <div className='margin-bottom-8-px'>
                                <span>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.ready-link-accounts-1'/>
                                </span>
                                <span className='link-money-landing-text-style-1 '>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.ready-link-accounts-1a'/>
                                </span>
                                <span>
                                    <FormattedMessage id='app.action-plan-link-money.welcome.ready-link-accounts-1b'/>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="submit-button-round btn-link-money margin-bottom-24-px">
                    <button id='link-money-landing-btn'
                            type={'button'}
                            className="btn btn-primary btn-block btn-lg"
                            onClick={(e) => history.push('/action-plan/link-money/gateway')}>
                        <FormattedMessage id='app.action-plan-link-money.button.link-accounts'/>
                    </button>
                </div>

                <div className='link-money-landing-link margin-bottom-24-px'>
                    <a href={"/action-plan"}><FormattedMessage id='app.action-plan-link-money.button.cancel'/></a>
                </div>

                <div className='link-money-landing-privacy'>
                    <FormattedMessage id='app.action-plan-link-money.welcome.privacy-1'/>
                </div>

                <div className='link-money-landing-encrypted'>
                    <FormattedMessage id='app.action-plan-link-money.welcome.privacy-2'/>
                </div>

                <FooterActionPlan/>
            </div>
        </div>
    )
}