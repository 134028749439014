import React, {useEffect, useState} from 'react'
import DclAccepted from "./dcl-accepted";
import DclNotAccepted from "./dcl-not-accepted";
import {useSelector} from "react-redux";

export default function RoundRobinDcl(props) {

  const dcl = useSelector(state => state.dcl)

  const chooseProduct = () => {
    if (dcl !== null && dcl.onboardingProductStatus === 'Accepted') {
      return <DclAccepted calendlyWidget={props.displayCalendlyWidget()} />
    } else {
      return <DclNotAccepted calendlyWidget={props.displayCalendlyWidget()} />
    }
  }

  return (
    <div>
      {chooseProduct()}
    </div>
  )
}
