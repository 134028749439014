import React from 'react'

export const WhoWeAreMission = ({
                                  style = {},
                                  fill = '#000000',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 24 21.06',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M6.83,5.43c-1.22-1.24-1.22-3.26,0-4.5,1.22-1.24,3.21-1.24,4.43,0l.74.75.74-.75c1.22-1.24,3.21-1.24,4.43,0,1.22,1.24,1.22,3.26,0,4.5l-4.23,4.3c-.26.26-.6.39-.94.39s-.68-.13-.94-.39l-4.23-4.3ZM7.81,14.39c-.33,0-.65.1-.92.28l-3.33,2.22c-.16.11-.36.17-.55.17H1c-.55,0-1-.45-1-1s.45-1,1-1h1.7l3.07-2.05c.6-.4,1.31-.62,2.04-.62h6.52c1.47,0,2.67,1.2,2.67,2.67v.08l2.7-1.67c.45-.27.95-.42,1.48-.42h.31c1.39,0,2.51,1.12,2.51,2.51,0,.85-.43,1.65-1.15,2.11l-3.98,2.58c-.81.52-1.75.8-2.72.8H1c-.55,0-1-.45-1-1s.45-1,1-1h15.15c.58,0,1.15-.17,1.63-.48l3.98-2.58c.15-.1.23-.25.23-.43,0-.28-.23-.51-.51-.51h-.31c-.15,0-.3.04-.43.12l-3.9,2.4c-.16.1-.34.15-.52.15h-5.66c-.55,0-1-.45-1-1s.45-1,1-1h3.67c.37,0,.67-.3.67-.67s-.3-.67-.67-.67h-6.52Z" fill="#2e2f2f"/>
    </g>
  </svg>;

export default WhoWeAreMission