import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainer from '../containers/header-container'
import FooterCalendlyContainer from '../containers/footer-calendly-container'
import {connect, useDispatch, useSelector} from "react-redux";
import {setActiveCounselor} from "../actions/counselor-actions";
import {getContactWithEmail, reGetContact, update} from "../actions/registration-actions";
import CreditReportFormContainer from "../containers/credit-report-form-container";
import {reduxForm} from "redux-form";
import ValidationService from "../services/validation-service";
import {debtReportAttempt} from "../actions/debt-report-actions";
import {injectIntl} from "react-intl";
import moment from "moment";
import {sendNudge} from "../actions/nudge-actions";
import {checkContactExists, getOrCreateLoginRecord} from "../actions/b-flow-actions";
import {displayLoadingIcon} from "../actions/loading-icon-action";

const getCounselor = (contactId, counselors) => {
    let counselor = null;
    for (let i=0; i<counselors.length; i++) {
        let counselorTmp = counselors[i];
        if (counselorTmp.contactId === contactId) {
            counselor = counselorTmp;
        }
    }
    return counselor;
}

const isSubmitDisabled = (props) => {
    return props.pristine ||
        props.submitting ||
        !props.valid ||
        props.error === 'app.errors.contact.update'
}

function RoundRobinConfirmationAndCreditReportForm(props) {

    const employer = useSelector(state => state.employer)
    const activeCounselor = useSelector(state => state.activeCounselor)
    const activeCounselors = useSelector(state => state.activeCounselors)
    const login = useSelector(state => state.login)
    const registration = useSelector(state => state.registration)
    const showAlert = useSelector(state => state.displayAlert)
    const nameOfAlert = useSelector(state => state.alertName)
    const updateRegistrationCompleted = useSelector(state => state.updateRegistrationCompleted)
    const dcl = useSelector(state => state.dcl)
    const contactCreated = useSelector(state => state.contactCreated)

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (registration && registration.newAppointment && registration.newAppointment.coach) {
            dispatch(setActiveCounselor(getCounselor(registration.newAppointment.coach, activeCounselors)))
        }
    }, [registration])

    const renderCounselorImage = () => {
        if (activeCounselor) {
            return (<img src={`/api/counselor/${activeCounselor.userId}/image`}/>)
        } else {
            return ''
        }
    }

    useEffect(() => {
        dispatch(displayLoadingIcon(true))
    }, []);

    useEffect(() => {
        if (registration === null && login === null){
            dispatch(displayLoadingIcon(false))
            props.history.push(`/select-by-availability`)
        } else if (login != null && login !== '') {
            if (login.nextPage === 'PASSWORD') {
                dispatch(displayLoadingIcon(false))
                props.history.push('/enter-your-password')
            } else if (login.nextPage === null && login.samlEntity == null) {
                dispatch(displayLoadingIcon(false))
                props.history.push('/action-plan')
            }
        }
    })

    useEffect(() => {
        if (updateRegistrationCompleted === true) {
            if (dcl !== null && dcl.onboardingProductStatus === 'Accepted') {
                props.history.push('/make-a-password')
            } else {
                goToSurveyMonkey()
            }
        }
    }, [updateRegistrationCompleted]);

    useEffect(() => {
        if (registration && login == null && registration.error == false) {
            dispatch(checkContactExists(registration.email)).then(() => {
                finishedLoading()
            })
        } else {
            if (registration !== null && registration.id == ''){
                dispatch(getContactWithEmail(login.contactId))
            }
            if (registration.error == true) {
                finishedLoading()
            }
        }
    })

    useEffect(() => {
        if (contactCreated == true){
            dispatch(getOrCreateLoginRecord("Client")).then(() => {
                finishedLoading()
            })
        }
    }, [contactCreated]);

    const showSkipLink = () => {
        if (dcl !== null && dcl.onboardingProductStatus === 'Accepted') {
            return <div className='text-ap additional-links margin-bottom-16-px'>
                <Link to='/make-a-password'
                      id='skip'
                >
                    <FormattedMessage id='app.b-flow.pull-credit-report.text.skip'/>
                </Link>
            </div>
        } else {
            return <div className='text-ap additional-links margin-bottom-16-px'>
                <Link to='#'
                      id='skip'
                      onClick={(e) => goToSurveyMonkeyLink(e)}>
                    <FormattedMessage id='app.b-flow.pull-credit-report.text.skip'/>
                </Link>
            </div>
        }
    }

    function goToSurveyMonkeyLink(e) {
        e.preventDefault()
        e.stopPropagation()
        dispatch(sendNudge(registration.id, "crfremind"))
        goToSurveyMonkey()
    }

    function goToSurveyMonkey() {
        let link = registration.clientSmLink
        if (link !== null) {
            window.location.href = link;
        }
    }

    function displayAppointmentDetails(appointment) {
        let utcMoment = moment(appointment.startDateTime)
        let localMoment = utcMoment.tz(appointment.startDateTime, appointment.timezone);

        let appointmentDetails = {
            apptDayofMonth: localMoment.format('D'),
            apptDayofWeekLong: localMoment.format('dddd'),
            apptDayofWeekShort: localMoment.format('ddd'),
            apptMonthLong: localMoment.format('MMMM'),
            apptMonthShort: localMoment.format('MMM'),
            apptTime: localMoment.format('h:mm A'),
            apptYear: localMoment.format('YYYY'),
            apptTimeZone: appointment.timezone,
            rescheduleUrl: appointment.rescheduleUrl
        }

        return <div>
                    <div className='time-sessions'>
                        {appointmentDetails.apptTime} {appointmentDetails.apptTimeZone != null ? appointmentDetails.apptTimeZone[0] : ''}T
                        <FormattedMessage id='app.action-plan-sessions.on'/>
                    </div>
                    <div className='time-sessions'>
                        <FormattedMessage id={getDayofWeek(appointmentDetails.apptDayofWeekShort)}/>, <FormattedMessage
                        id={getMonth(appointmentDetails.apptMonthLong)}/> {appointmentDetails.apptDayofMonth}, {appointmentDetails.apptYear}
                    </div>
                    <div className='time-sessions'>
                        <a href={appointmentDetails.rescheduleUrl} target="_blank"><FormattedMessage id='app.b-flow.session-scheduled.reschedule-link'/></a>
                    </div>
            </div>
    }

    function getDayofWeek(s) {
        return 'app.shift-table.dayname.' + s;
    }

    function getMonth(s) {
        return 'app.shift-table.monthname.' + s;
    }

    function finishedLoading() {
        setTimeout(() => {
            dispatch(displayLoadingIcon(false))
        }, 1500)
    }

    return (
        <div className='b-flow'>
            <HeaderContainer
                displayAlert={showAlert}
                alertName={nameOfAlert}/>

            { login && registration ?
                <div>
                    { registration && registration.newAppointment && registration.error == false ?
                        <div
                            className={`container-ap ptxxl margin-bottom-24-px ${dcl !== null && dcl.onboardingProductStatus === 'Accepted' ? 'dcl-round-robin' : ''}`}>
                            <div>
                                <h1>
                                    <FormattedMessage id='app.schedule-confirmation.title'/>
                                </h1>

                                <div className='schedule-confirmation-mobile'>
                                    <p>
                                        <FormattedMessage id='app.b-flow.session-scheduled.description-1a'/>
                                        {activeCounselor ? activeCounselor.firstName : ''}
                                        <FormattedMessage id='app.b-flow.session-scheduled.description-1b'/>
                                    </p>
                                    <p className='schedule-confirmation-upcoming-session-text margin-top-24-px'>
                                        <FormattedMessage id='app.b-flow.session-scheduled.upcoming-session'/>
                                    </p>
                                    <div className='schedule-confirmation-upcoming-session'>
                                        <div className='counselor-b-flow-image-container session-scheduled'>
                                            {renderCounselorImage()}
                                        </div>
                                        <div className='schedule-confirmation-upcoming-session-details'>
                                            <p className='margin-bottom-40-px'>
                                                <div className='counselor-name-sessions'>
                                                    {activeCounselor ? activeCounselor.firstName : ''} {activeCounselor ? activeCounselor.lastName : ''}
                                                </div>
                                                {displayAppointmentDetails(registration.newAppointment)}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='schedule-confirmation-desktop'>
                                    <div className='schedule-confirmation-upcoming-session'>
                                        <div className='counselor-b-flow-image-container session-scheduled'>
                                            {renderCounselorImage()}
                                        </div>
                                        <div className='schedule-confirmation-upcoming-session-details'>
                                            <p className='schedule-confirmation-upcoming-session-text margin-bottom-24-px'>
                                                <FormattedMessage id='app.b-flow.session-scheduled.upcoming-session'/>
                                            </p>
                                            <p className='margin-bottom-40-px'>
                                                <div className='counselor-name-sessions'>
                                                    {activeCounselor ? activeCounselor.firstName : ''} {activeCounselor ? activeCounselor.lastName : ''}
                                                </div>
                                                {displayAppointmentDetails(registration.newAppointment)}
                                            </p>
                                            <p>
                                                <FormattedMessage id='app.b-flow.session-scheduled.description-1a'/>
                                                {activeCounselor ? activeCounselor.firstName : ''}
                                                <FormattedMessage id='app.b-flow.session-scheduled.description-1b'/>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='dotted-line margin-top-24-px'></div>

                            {dcl !== null && dcl.onboardingProductStatus === 'Accepted' ?
                                <div>
                                    <h1 className='action-plan-subtitle margin-bottom-24-px'>
                                        <FormattedMessage id='app.b-flow.credit-report-form-page.dcl.title'/>
                                    </h1>
                                    <p className='margin-bottom-24-px'>
                                        <FormattedMessage id='app.b-flow.credit-report-form-page.dcl.text'/>
                                    </p>
                                </div>
                                :
                                <div>
                                    <h1 className='action-plan-subtitle margin-bottom-24-px'>
                                        <FormattedMessage id='app.b-flow.credit-report-form-page.title'/>
                                    </h1>

                                    <p>
                                        <FormattedMessage id='app.b-flow.credit-report-form-page.text'/>
                                    </p>
                                    <p className='margin-bottom-24-px'>
                                        <FormattedMessage id='app.b-flow.credit-report-form-page.text-2'/>
                                    </p>
                                </div>
                            }

                            <form onSubmit={props.handleSubmit}>

                                <CreditReportFormContainer/>

                                <div className='verified-and-secured margin-bottom-24-px'>
                                    <div className='goDaddySecuritySeal'/>
                                </div>

                                <div className='submit-button-round margin-bottom-24-px'>
                                    <button id='pull-report'
                                            type={'submit'}
                                            className='btn btn-primary btn-block btn-lg'
                                            disabled={isSubmitDisabled(props)}>
                                        <FormattedMessage id='app.b-flow.credit-report-form-page.button.continue'/>
                                    </button>
                                </div>

                                {showSkipLink()}

                            </form>

                            <FooterCalendlyContainer/>
                        </div>
                        :
                        '' }
                </div>
              : '' }

            {registration && registration.error == true ?
                <div className='container-ap ptxxl margin-bottom-24-px text-center'>
                    <h2>We could not create your appointment.</h2>
                    <p>
                        Please contact us at <a href="mailto:support@mytrustplus.org">support@mytrustplus.org</a></p>
                    <p>
                        &nbsp;
                        <br/>
                        &nbsp;
                        <br/>
                    </p>
                    <FooterCalendlyContainer/>
                </div>
            : '' }
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        registration: state.registration
    }
}

const UpdateContact = reduxForm({
    form: 'registration',
    validate: ValidationService.validateDebtSummaryCreditReportFormFields,
    onSubmit: (values, dispatch, props) => {
        dispatch(debtReportAttempt(true))
        dispatch(update(values))
    },
    destroyOnUnmount: false
})(injectIntl(RoundRobinConfirmationAndCreditReportForm))

export default connect(mapStateToProps)(UpdateContact)
