import React from 'react'

export const MagnifyingGlass = ({
                                    style = {},
                                    fill = '#000000',
                                    width = '100%',
                                    className = '',
                                    height = '100%',
                                    viewBox = '0 0 22 21',
                                }) =>

<svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 302.58 371.34"
>
    <g id="Layer_2-2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1-2">
        <g>
        <g>
        <path d="M14.52,138.22l57.66,221.19c1.74,6.68,8.57,10.69,15.26,8.95l173.14-45.13c6.68-1.74,10.69-8.57,8.95-15.26l-49.39-189.46c-43.64,61.21-131.64,32.36-121.52-62.41l-22.59,5.89-22.72,5.92" fill="#fff" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.15"/>
        <path d="M11.93,78.7c-6.68,1.74-10.69,8.57-8.95,15.26l11.54,44.27,38.78-70.31-41.38,10.79h.01Z" fill="#ef3e42" stroke="#ef3e42" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.15"/>
        <line x1="53.31" y1="67.91" x2="14.52" y2="138.22" fill="none" stroke="#ef3e42" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.15"/>
        <path d="M53.31,67.91l-41.38,10.79c-6.68,1.74-10.69,8.57-8.95,15.26l11.54,44.27" fill="none" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.15"/>
    </g>
    <line x1="52.89" y1="147.19" x2="95.06" y2="136.19" fill="none" stroke="#aee0e8" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.86"/>
    <line x1="72" y1="220.52" x2="201.87" y2="186.67" fill="none" stroke="#aee0e8" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.86"/>
    <line x1="92.24" y1="298.17" x2="222.11" y2="264.32" fill="none" stroke="#aee0e8" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.86"/>
    <path d="M138.86,37.32s35.56-22.79,70.94,15.3" fill="none" stroke="#aee0e8" stroke-linecap="round" stroke-linejoin="round" stroke-width="9.01"/>
    <path d="M272.34,107.06c-2.98-1.43-6.55-.18-7.99,2.8l-3.05,6.33-22.82-10.97c4.45-11.63,6.17-24.49,4.45-37.7C237.4,25.06,198.49-4.88,156.02.66c-42.47,5.53-72.4,44.45-66.87,86.91s44.45,72.4,86.91,66.87c23.77-3.1,43.61-16.66,55.51-35.44l23.06,11.08-3.04,6.33c-1.43,2.98-.18,6.55,2.8,7.99l48.19,23.16v-45.96l-30.24-14.54h0ZM174.24,140.42c-34.72,4.52-66.54-19.95-71.06-54.68-4.52-34.72,19.95-66.54,54.68-71.06,34.72-4.52,66.54,19.95,71.06,54.68,4.52,34.72-19.95,66.54-54.68,71.06h0Z" fill="#aee0e8"/>
    </g>
    </g>
    </g>
</svg>

export default MagnifyingGlass