import React from 'react'

export const Scan = ({
                              style = {},
                              fill = '#141006',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 511.91 512.05',
                          }) =>

    <svg
        width="42"
        height="42"
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.91 512.05">
    <g id="Layer_1-2" data-name="Layer 1">
        <path d="M208,48c88.37,0,160,71.63,160,160s-71.63,160-160,160S48,296.37,48,208,119.63,48,208,48ZM208,416c48.8,0,93.7-16.8,129.1-44.9l133.9,133.9c9.4,9.4,24.6,9.4,33.9,0,9.3-9.4,9.4-24.6,0-33.9l-133.8-134c28.1-35.4,44.9-80.3,44.9-129.1C416,93.1,322.9,0,208,0S0,93.1,0,208s93.1,208,208,208ZM104,216v64c0,13.3,10.7,24,24,24s24-10.7,24-24v-64c0-13.3-10.7-24-24-24s-24,10.7-24,24ZM184,120v160c0,13.3,10.7,24,24,24s24-10.7,24-24V120c0-13.3-10.7-24-24-24s-24,10.7-24,24ZM264,184v96c0,13.3,10.7,24,24,24s24-10.7,24-24v-96c0-13.3-10.7-24-24-24s-24,10.7-24,24Z" fill="#141006"/>
    </g>
    </svg>

export default Scan