import React from 'react'
import {useCalendlyEventListener, InlineWidget} from "react-calendly";

function stripPhone(phone) {
  let trimmedPhone = phone.trim();
  let one = trimmedPhone.substring(0, 1);
  let two = trimmedPhone.substring(0, 2);
  if (one === '1') {
    return trimmedPhone.slice(1).trim();
  } else if (two === "+1") {
    return trimmedPhone.slice(2).trim();
  } else {
    return trimmedPhone;
  }
}

function calendlyUrl(registration, locale, calendlyRoundRobinUrl, calendlyRoundRobinUrlSpanish) {
  let url = locale === 'en-US' ? calendlyRoundRobinUrl : calendlyRoundRobinUrlSpanish
  if (registration == null ) {
    return url;
  } else if (registration.mobilePhone == null) {
    let queryString = `?first_name=${registration.firstName}&last_name=${registration.lastName}`
    return `${url}${queryString}`
  } else {
    let queryString = `?first_name=${registration.firstName}&last_name=${registration.lastName}&a1=1${stripPhone(registration.mobilePhone)}`
    return `${url}${queryString}`
  }
}

export const CalendlyWidgetHelperRoundRobin = props => {

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => { props.onEventScheduled(e) },
      });

    let email = props.registration != null ? props.registration.email : ''
    let a4 = props.employer != null ? (props.employer.name != "No Employer" ? props.employer.name : '') : ''

    return (
        <InlineWidget url={calendlyUrl(props.registration, props.locale, props.calendlyRoundRobinUrl, props.calendlyRoundRobinUrlSpanish)}
            prefill={{
              email: email,
              customAnswers: {
                a4: a4
              }
            }}
                      styles={{
                        'min-width': '320px',
                        height: '100%'
                      }}

        />
        )
    }

export default CalendlyWidgetHelperRoundRobin
