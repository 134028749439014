import React from 'react'

export const HowItWorksStep5 = ({
                                  style = {},
                                  fill = '#000000',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 94.4 105',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M75.88,38.05c0-3.47-2.8-6.31-6.27-6.31H24.79c-3.45,0-6.27,2.81-6.27,6.31v6.15h57.33v-6.15h.02Z" fill="#ddf2f7" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M18.52,44.2v28.82c0,3.47,2.8,6.31,6.27,6.31h44.81c3.45,0,6.27-2.81,6.27-6.31v-28.82H18.52Z" fill="#fff" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M33.95,24.96h-.02c-1.47,0-2.66,1.2-2.66,2.68v7.08c0,1.48,1.19,2.68,2.66,2.68h.02c1.47,0,2.66-1.2,2.66-2.68v-7.08c0-1.48-1.19-2.68-2.66-2.68Z" fill="url(#linear-gradient-2)" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M60.48,24.96h-.02c-1.47,0-2.66,1.2-2.66,2.68v7.08c0,1.48,1.19,2.68,2.66,2.68h.02c1.47,0,2.66-1.2,2.66-2.68v-7.08c0-1.48-1.19-2.68-2.66-2.68Z" fill="url(#linear-gradient-3)" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M32.14,49.83h-4.24c-.99,0-1.79.81-1.79,1.8v4.27c0,1,.8,1.8,1.79,1.8h4.24c.99,0,1.79-.81,1.79-1.8v-4.27c0-1-.8-1.8-1.79-1.8Z" fill="#fff" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M32.14,63.67h-4.24c-.99,0-1.79.81-1.79,1.8v4.27c0,1,.8,1.8,1.79,1.8h4.24c.99,0,1.79-.81,1.79-1.8v-4.27c0-1-.8-1.8-1.79-1.8Z" fill="#00c7b1" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M49.32,49.83h-4.24c-.99,0-1.79.81-1.79,1.8v4.27c0,1,.8,1.8,1.79,1.8h4.24c.99,0,1.79-.81,1.79-1.8v-4.27c0-1-.8-1.8-1.79-1.8Z" fill="#fff" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M66.5,49.83h-4.24c-.99,0-1.79.81-1.79,1.8v4.27c0,1,.8,1.8,1.79,1.8h4.24c.99,0,1.79-.81,1.79-1.8v-4.27c0-1-.8-1.8-1.79-1.8Z" fill="#fff" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M49.32,63.67h-4.24c-.99,0-1.79.81-1.79,1.8v4.27c0,1,.8,1.8,1.79,1.8h4.24c.99,0,1.79-.81,1.79-1.8v-4.27c0-1-.8-1.8-1.79-1.8Z" fill="#fff" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M66.5,63.67h-4.24c-.99,0-1.79.81-1.79,1.8v4.27c0,1,.8,1.8,1.79,1.8h4.24c.99,0,1.79-.81,1.79-1.8v-4.27c0-1-.8-1.8-1.79-1.8Z" fill="#fff" stroke="#003057" stroke-miterlimit="10"/>
      <path d="M32.04,65.53l-4.05,4.17" fill="none" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width=".7"/>
      <path d="M27.99,65.53l4.05,4.17" fill="none" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width=".7"/>
      <path d="M43.42,99.82h-.12C19.02,97.82,0,77.04,0,52.51S17.59,8.56,40.94,5.43c.79-.12,1.52.45,1.62,1.24.1.8-.44,1.53-1.23,1.63C19.41,11.25,2.89,30.23,2.89,52.51s17.86,42.54,40.65,44.42c.79.06,1.39.78,1.33,1.57-.06.76-.69,1.34-1.45,1.34v-.02Z" fill="#003057"/>
      <path d="M40.76,14.11l-1.14-14.11,7.58,6.48-6.44,7.63Z" fill="#003057"/>
      <path d="M53.29,99.57c-.71,0-1.33-.52-1.43-1.26-.1-.8.44-1.53,1.23-1.63,21.9-2.93,38.41-21.91,38.41-44.17S74.74,10.93,52.51,8.25c-.79-.1-1.37-.82-1.27-1.61.1-.8.81-1.36,1.6-1.28,23.69,2.85,41.55,23.11,41.55,47.13s-17.59,43.95-40.94,47.06h-.19l.02.02Z" fill="#003057"/>
      <path d="M53.63,90.91l1.14,14.09-7.56-6.48,6.42-7.61Z" fill="#003057"/>
    </g>
  </svg>;

export default HowItWorksStep5