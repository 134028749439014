import React from 'react'

export const Video = ({
                            style = {},
                            fill = '#fff',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 41 32',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="icon-video_desktop">
      <path id="icon-video" d="M7.13151 6.95096C7.13151 5.41623 8.37457 4.17318 9.90929 4.17318H21.0204C21.944 4.17318 22.6871 3.43012 22.6871 2.50651C22.6871 1.5829 21.944 0.839844 21.0204 0.839844H9.90929C6.53429 0.839844 3.79818 3.57596 3.79818 6.95096V9.86762C1.88151 10.3607 0.464844 12.1037 0.464844 14.1732V27.5065C0.464844 29.9579 2.4579 31.951 4.90929 31.951H22.6871C25.1385 31.951 27.1315 29.9579 27.1315 27.5065V14.1732C27.1315 11.7218 25.1385 9.72873 22.6871 9.72873H7.13151V6.95096ZM4.90929 13.0621H22.6871C23.2982 13.0621 23.7982 13.5621 23.7982 14.1732V27.5065C23.7982 28.1176 23.2982 28.6176 22.6871 28.6176H4.90929C4.29818 28.6176 3.79818 28.1176 3.79818 27.5065V14.1732C3.79818 13.5621 4.29818 13.0621 4.90929 13.0621ZM36.5968 30.5968C36.951 30.7565 37.3329 30.8398 37.7218 30.8398C39.2357 30.8398 40.4648 29.6107 40.4648 28.0968V13.5829C40.4648 12.069 39.2357 10.8398 37.7218 10.8398C37.3329 10.8398 36.951 10.9232 36.5968 11.0829L29.3537 14.3398V17.9926L37.1315 14.4926V27.1871L29.3537 23.6871V27.3398L36.5968 30.5968ZM6.0204 16.951C6.0204 17.8746 6.76345 18.6176 7.68707 18.6176H19.9093C20.8329 18.6176 21.576 17.8746 21.576 16.951C21.576 16.0273 20.8329 15.2843 19.9093 15.2843H7.68707C6.76345 15.2843 6.0204 16.0273 6.0204 16.951Z" fill="#0032A0"/>
    </g>
  </svg>;

export default Video