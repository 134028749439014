import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from '../footer-action-plan'
import CalendlyWidgetHelperRoundRobin from "../helpers/CalendlyWidgetHelperRoundRobin";
import {
  calendlyEventRoundRobin,setCurrentCalendlyConfirmationPath, getBookedAppointments
} from "../../actions/calendly-actions";

export default function ActionPlanSelectByAvailibility(props) {

  const [appointmentSubmitted, setValue] = useState(null);
  const locale = useSelector((state) => state.locale)
  const registration = useSelector(state => state.registration);
  const login = useSelector((state) => state.login)

  const calendlyRoundRobinUrl = useSelector(state => state.calendlyRoundRobinUrl);
  const calendlyRoundRobinUrlSpanish = useSelector(state => state.calendlyRoundRobinUrlSpanish);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (login === null && registration === null) {
      console.log("ActionPlanSelectByAvailibility useEffect")
      console.log(location)
      const string = location.pathname
      const schedule = 'appointment-schedule'
      const select = 'select-appointment'
      if (string.includes(schedule) || string.includes(select)) {
        console.log("to AP with referral")
        history.push(`/welcome?referral=${location.pathname}`)
      }
    }
  }, [login, registration])

  const handleAppointmentSubmit = () => {
      setValue(true);
    }

  const onEventScheduled = (event) => {
        handleAppointmentSubmit()
        createCalendlyEvent(event)
    }

  const createCalendlyEvent = (event) => {
      dispatch(calendlyEventRoundRobin(event.data.payload.event.uri, event.data.payload.invitee.uri)).then(() => {
        dispatch(getBookedAppointments()).then(() => {
          dispatch(setCurrentCalendlyConfirmationPath('/action-plan/sessions'))
        history.push('/action-plan/sessions')
        })
      })
    }




  return (
      <div id='ap-select-by-availibility'>
        <ActionPlanNav />
        { login !== null  ?
          <div id='action-plan-content'>

            <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <h1 className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.action-plan-sessions.page-title'/>
              </h1>
            </div>
            { appointmentSubmitted ? <div>Plase wait while we submit your appointment request.</div> : <div>
              <div>
                <p>
                  <FormattedMessage id='app.round-robin.pick-a-time.select-by-availability'/>
                </p>
                <p>
                  <FormattedMessage id='app.round-robin.pick-a-time.select-date-instruction'/>
                </p>
              </div>

              <div className='select-by-availability-container'>
                <CalendlyWidgetHelperRoundRobin
                  locale={locale}
                  registration={registration}
                  onEventScheduled={(event)=>onEventScheduled(event)}
                  calendlyRoundRobinUrl={calendlyRoundRobinUrl}
                  calendlyRoundRobinUrlSpanish={calendlyRoundRobinUrlSpanish}
                  aria-label='A calendar widget to schedule an appointment'
                  />
              </div>

            </div>
          }




            <FooterActionPlan />
          </div>
          :
          <div>YUCK</div>
        }
      </div>)

};