import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";
import LandingFooter from "../landing-footer";
import IntroductionTagline from "./introduction-tagline";
import IntroductionHeader from "./introduction-header";
import HomePageChecklist from "../home-page/home-page-checklist";
import IntroductionHowItWorks from "./introduction-how-it-works";
import IntroductionTestimonials from "./introduction-testimonials";
import IntroductionPageCounselors from "../introduction-page-counselors";
import Icon from "../../../resources/icons";
import HomePageChecklistWa from "../home-page/home-page-checklist-wa";

function IntroductionDefault(props) {

  return(

    <div className='intro-container'>
      <IntroductionHeader onLocaleToggle={(locale) => props.onLocaleToggle(locale)}
                          locale={props.locale}
                          history={props.history}
                          employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
      />

      <main>
        <div>
          <div className={`intro-hero ${props.locale === 'es-US' ? 'spanish' : ''}`}>
            <div className='intro-hero-label'><FormattedMessage id='app.landing-page.introduction.hero-label-mobile'/></div>
            <span role="img" aria-label={`${props.locale === 'es-US' ? 'Los clientes de TrustPlus como Renee y Luis pueden hablar con sus entrenadores financieros expertos a través de chat de video en sus computadoras o teléfonos inteligentes. Elayna, la entrenadora financiera dedicada de TrustPlus, comparte consejos financieros útiles durante una sesión de entrenamiento remoto.' : 'TrustPlus clients like Renee and Luis can speak to their expert financial coaches via video chat on their computers or smartphones. Dedicated TrustPlus financial coach Elayna shares helpful financial tips during a remote coaching session.'}`}></span>
            <div className='landing-page-nav-wrapper'>
              <div className='landing-container'>
                <div className='landing-page-nav flex'>
                  <div className='landing-page-nav-item'>
                    <a href='#how-it-works'>
                      <FormattedMessage id='app.landing-page.how-it-works.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a href='#testimonials'>
                      <FormattedMessage id='app.landing-page.testimonials.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a href='#who-we-are'>
                      <FormattedMessage id='app.landing-page.who-we-are.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a href='#our-coaches'>
                      <FormattedMessage id='app.landing-page.coaches.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a href='/0000/learn-more' target='_blank'>
                      <FormattedMessage id='app.footer-link-landing-page.faq'/>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='intro-content-landing-page-wrapper intro-content-landing-page-wrapper-desktop'>
              {
                props.employer && props.employer.code && props.employer.code.toLowerCase() === "ij08" ?
                  <HomePageChecklistWa determineNextLocation={() => props.determineNextLocation()}
                                     employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
                  /> :

                  <HomePageChecklist determineNextLocation={() => props.determineNextLocation()}
                                     employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
                  />
              }
              {
                props.employer && props.employer.code && props.employer.code !== "0000" ?
                  <div className='landing-container intro-hero-logo'>
                    <div className='intro-hero-logo-title'>
                      <FormattedMessage id='app.landing-page.company-logo.title'/>
                    </div>
                    {props.employerImage()}
                  </div> : <div/>
              }

            </div>
          </div>

          <div className='intro-content-landing-page-wrapper-mobile'>
            {
              props.employer && props.employer.code && props.employer.code.toLowerCase() === "ij08" ?
                <HomePageChecklistWa determineNextLocation={() => props.determineNextLocation()}
                                   employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
                /> :
                <HomePageChecklist determineNextLocation={() => props.determineNextLocation()}
                                   employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
                />
            }
            {
              props.employer && props.employer.code && props.employer.code !== "0000" ?
                <div className='landing-container intro-hero-logo'>
                  <div className='intro-hero-logo-title'>
                    <FormattedMessage id='app.landing-page.company-logo.title'/>
                  </div>
                  {props.employerImage()}
                </div> : <div/>
            }

          </div>


          <a id='how-it-works'></a>
          <IntroductionHowItWorks determineNextLocation={()=> props.determineNextLocation()} />

          <a id='testimonials'></a>
          <IntroductionTestimonials intl={props.intl} />

          <a id='who-we-are'></a>
          <div className='who-we-are'>
            <div className='landing-container'>
              <h2>
                <FormattedMessage id='app.landing-page.who-we-are.title'/>
              </h2>
              <div className='who-we-are-container'>
                <div className='who-we-are-text-container'>
                  <h3 className='desktop'>
                    <FormattedMessage id='app.landing-page.who-we-are.main-header'/>
                  </h3>
                  <h4>
                    <FormattedMessage id='app.landing-page.who-we-are.secondary-header'/>
                  </h4>
                  <div className='who-we-are-text-wrapper landing-paragraph'>
                    <div className='who-we-are-text-row'>
                      <div className='who-we-are-icon'>
                        <Icon name='who-we-are-company' />
                      </div>
                      <div className='who-we-are-header'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-header-1'/>
                        <span className='mobile-text'><FormattedMessage id='app.landing-page.who-we-are.detail-1'/> </span>
                      </div>
                      <div className='who-we-are-details'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-1'/>
                      </div>
                    </div>
                    <div className='who-we-are-text-row'>
                      <div className='who-we-are-icon'>
                        <Icon name='who-we-are-mission' />
                      </div>
                      <div className='who-we-are-header'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-header-2'/>
                        <span className='mobile-text'><FormattedMessage id='app.landing-page.who-we-are.detail-2'/> </span>
                      </div>
                      <div className='who-we-are-details'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-2'/>
                      </div>
                    </div>
                    <div className='who-we-are-text-row'>
                      <div className='who-we-are-icon'>
                        <Icon name='who-we-are-founded' />
                      </div>
                      <div className='who-we-are-header'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-header-3'/>
                        <span className='mobile-text'><FormattedMessage id='app.landing-page.who-we-are.detail-3'/> </span>
                      </div>
                      <div className='who-we-are-details'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-3'/>
                      </div>
                    </div>
                    <div className='who-we-are-text-row'>
                      <div className='who-we-are-icon'>
                        <Icon name='who-we-are-ceo' />
                      </div>
                      <div className='who-we-are-header'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-header-4'/>
                        <span className='mobile-text'><FormattedMessage id='app.landing-page.who-we-are.detail-4'/> </span>
                      </div>
                      <div className='who-we-are-details'>
                        <FormattedMessage id='app.landing-page.who-we-are.detail-4'/>
                      </div>
                    </div>
                  </div>
                  <div className='landing-paragraph'>
                    <p className='margin-bottom-24-px'>
                     <FormattedMessage id='app.landing-page.who-we-are.summary-1'/>
                    </p>
                    <p>
                      <FormattedMessage id='app.landing-page.who-we-are.summary-2'/>
                    </p>
                  </div>

                </div>
                <div className='who-we-are-image-wrapper'>
                  <h3 className='mobile'>
                    <FormattedMessage id='app.landing-page.who-we-are.main-header'/>
                  </h3>
                  <div className='who-we-are-image'>
                  </div>
                  <div className='who-we-are-image-caption'>
                    <FormattedMessage id='app.landing-page.who-we-are.image-caption'/>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {props.activeCounselors != null && props.activeCounselors.length !== 0 ?
            <div className='coaches-landing-page'>
              <a id='our-coaches'></a>
              <div className='landing-container'>
                <h2><FormattedMessage id='app.landing-page.coaches.title'/></h2>
                <IntroductionPageCounselors
                  activeCounselors={props.activeCounselors}
                  locale={props.locale}
                  employer={props.employer}/>
              </div>
            </div>
            : ''
          }

          <IntroductionTagline determineNextLocation={() => props.determineNextLocation()}
                               employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
          />

          <LandingFooter />
        </div>
      </main>
    </div>



    )
}
export default IntroductionDefault