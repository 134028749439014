import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import {getEmployer} from "../actions/employer-actions";
import {toggleLocale, updateMsgFile} from "../actions/locale-actions";
import {Redirect, Route, Switch} from "react-router";
import IntroductionContainer from "../containers/introduction-container";
import {updateOnboardingProductName} from "../actions/dcl-actions";
import {hotjar} from "react-hotjar";

const spanishEmployers = () => {
  return ['ya45', 'rn12', 'iz17']
}

function Employers() {
  console.log("Load the function Employers() and its routes");
  const employer = useSelector(state => state.employer)
  const locale = useSelector(state => state.locale)
  const login = useSelector(state => state.login)
  const { employerCode } = useParams()
  const dispatch = useDispatch()
  let history = useHistory()

   // TransUnion - clear the employerCode from the browser's address bar after fetching employer data
  useEffect(() => {
    if (employerCode === 'h310') {
      dispatch(getEmployer(employerCode.toLowerCase()))
      history.push('/')
    }
  });

  useEffect(() => {
    if (employer &&
      employer.code &&
      employerCode &&
      employerCode !== '0000' &&
      employer.code.toLowerCase() !== employerCode.toLowerCase()) {

      dispatch(getEmployer(employerCode)).then(() => {
        dispatch(updateMsgFile())
      })
    }
  }, [])

  useEffect(() => {
    if (employer &&
      employer.code &&
      employer.code === 'err') {

      history.push(`/`)
    }
  }, [employer])

  useEffect(() => {
    if (employerCode &&
      locale !== 'es-US' &&
      spanishEmployers().includes(employerCode.toLowerCase())) {
      dispatch(toggleLocale(locale))
    }
  }, [locale])

  useEffect(() => {
    if (login &&
      login.isCustomersContact === true  ) {
      // history.push(`/customer-portal`)
      history.push(`/action-plan`)
    }
  }, [login])

    useEffect(() => {
        let productName = grabParams("ProductName");
        if (productName === 'DebtConsolidationLoan') {
            dispatch(updateOnboardingProductName(productName))
        } else {
            dispatch(updateOnboardingProductName(""))
        }
    })

    useEffect(() => {
        let language = grabParams("lang");
        if (language === 'es' && locale !== 'es-US') {
            dispatch(toggleLocale(locale))
            history.push(window.location.pathname)
        }
    }, [locale]);

    function grabParams(params) {
        const url = location.search;
        const idParams = new URLSearchParams(url);
        const productName = idParams.get(params)
        return productName;
    }

    useEffect(() => {
        if (employer.code == 'ffnt' || employer.code == 'FFNT' && locale == 'es-US') {
            const options = {id: 5165091, sv: 6};
            hotjar.initialize(options);
        }
    });

  return (
    <Switch>

      <Redirect exact from="/:employerCode/appointment-schedule" to="/select-by-availability" />
      <Redirect exact from="/:employerCode/appointment-pick-coach" to="/select-by-availability" />
      <Redirect exact from="/:employerCode/enter-your-email-address" to="/select-by-availability" />

      <Route exact path="/:employerCode/sign-up" component={IntroductionContainer}/>
      <Route exact path="/:employerCode/sign-up-password" component={IntroductionContainer}/>

      <Route path="/:employerCode" component={IntroductionContainer}/>
    </Switch>
)

}

export default Employers
