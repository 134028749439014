import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";
import Icon from "../../../resources/icons";
import Slider from "react-slick";

function IntroductionHowItWorks(props) {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: "slider-dots",
        arrows: false
    };

    const slides = [1, 2, 3, 4, 5]

    return (
        <div className='how-it-works'>
            <div className='landing-container'>
                <h2><FormattedMessage id='app.landing-page.how-it-works.title'/></h2>
                   <div className='how-it-works-container'>
                      <div className='intro-container'>
                          <div className='intro-text landing-paragraph'>
                              <FormattedMessage id='app.landing-page.how-it-works.intro-1'/>
                          </div>
                          <div className='intro-text landing-paragraph'>
                              <FormattedMessage id='app.landing-page.how-it-works.intro-2'/>
                          </div>
                      </div>
                      <div className='steps-container-desktop'>
                          {slides.map((slide) =>
                              <div className='step-wrapper'>
                                  <div className='steps-number-title-wrapper'>
                                      <div className='steps-circle'>
                                          <div className='steps-number'>
                                              {slide}
                                          </div>
                                      </div>
                                      <div className='steps-title'>
                                          <FormattedMessage id={`app.landing-page.how-it-works.step-${slide}-title`}/>
                                      </div>
                                  </div>
                                  <div className='steps-icon'>
                                      <Icon name={`how-it-works-step-${slide}`}/>
                                  </div>
                                  <div className='step-paragraph landing-paragraph'>
                                      <FormattedMessage id={`app.landing-page.how-it-works.step-${slide}-text`}/>
                                  </div>
                              </div>)
                          }
                      </div>

                      <div className='steps-container-mobile'>
                          <Slider {...settings}>
                              {slides.map((slide) =>
                                  <div className='step-wrapper'>
                                      <div className='steps-number-title-wrapper'>
                                          <div className='steps-circle'>
                                              <div className='steps-number'>
                                                  {slide}
                                              </div>
                                          </div>
                                          <div className='steps-title'>
                                              <FormattedMessage id={`app.landing-page.how-it-works.step-${slide}-title`}/>
                                          </div>
                                      </div>
                                      <div className='steps-icon'>
                                          <Icon name={`how-it-works-step-${slide}`}/>
                                      </div>
                                      <div className='step-paragraph landing-paragraph'>
                                          <FormattedMessage id={`app.landing-page.how-it-works.step-${slide}-text`}/>
                                      </div>
                                  </div>)
                              }
                          </Slider>
                          <div className='intro-text landing-paragraph margin-top-24-px text-center'>
                              <FormattedMessage id='app.landing-page.how-it-works.slider-text'/>
                          </div>
                      </div>

                      <h3 className='how-it-works-tagline'>
                          <FormattedMessage id='app.landing-page.how-it-works.tagline'/>
                      </h3>
                      <div className='submit-button-round'>
                          <button className='btn btn-primary btn-block btn-intro lg-height'
                                  id='submit-next-location'
                                  onClick={() => props.determineNextLocation()}>
                              <FormattedMessage id='app.landing-page.introduction.first-step.button'/>
                          </button>
                      </div>
                </div>
            </div>
        </div>
    )
}

export default IntroductionHowItWorks