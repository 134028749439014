import React from 'react'

export const Bank = ({
                              style = {},
                              fill = '#141006',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 512 512',
                          }) =>

<svg
    width="42"
    height="42"
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
  <g id="Layer_1-2" data-name="Layer 1">
    <path d="M267.6,3c-7.2-4-16-4-23.2,0L17.6,128.1c-10.9,6-17.6,17.4-17.6,29.8,0,18.9,15.2,34.1,34.1,34.1h443.8c18.8,0,34.1-15.2,34.1-34.1,0-12.4-6.7-23.8-17.6-29.8L267.6,3ZM228.3,144H88.2l167.8-92.6,167.8,92.6h-140.1c2.7-4.7,4.3-10.2,4.3-16,0-17.7-14.3-32-32-32s-32,14.3-32,32c0,5.8,1.6,11.3,4.3,16ZM64,224v160c-13.3,0-24,10.7-24,24s10.7,24,24,24h392c13.3,0,24-10.7,24-24s-10.7-24-24-24h-8v-160h-48v160h-64v-160h-48v160h-64v-160h-48v160h-64v-160h-48ZM32,464c-13.3,0-24,10.7-24,24s10.7,24,24,24h456c13.3,0,24-10.7,24-24s-10.7-24-24-24H32Z" fill="#141006"/>
  </g>
</svg>

export default Bank